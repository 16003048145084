import axios from '../AxiosWrapper'
import {
  GET_MEMBERSHIP_TYPES_REQUEST,
  GET_MEMBERSHIP_TYPES_SUCCESS,
  GET_MEMBERSHIP_TYPES_FAIL
} from '../constants/membershipTypes.constants'

export const getMembershipTypes = () => async (dispatch) => {
  try {
    dispatch({ type: GET_MEMBERSHIP_TYPES_REQUEST })
    const res = await axios.get(
      `${process.env.REACT_APP_API_HOST}admin/v1/membership-types`
    )
    dispatch({
      type: GET_MEMBERSHIP_TYPES_SUCCESS,
      payload: res.data
    })
  } catch (error) {
    dispatch({
      type: GET_MEMBERSHIP_TYPES_FAIL
    })
  }
}
