import React from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Descriptions } from 'antd'
import { Col, Row } from 'react-bootstrap'
import { Button, CircularProgress, MenuItem } from '@material-ui/core/'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import { updateMember } from '../../redux/actions/Members.actions'
import TextInput from '../../shared/form/TextInput'
import SelectInput from '../../shared/form/SelectInput'
import ResponsiveDrawer from '../../shared/ResponsiveDrawer'

const validationSchema = Yup.object({
  id: Yup.number()
    .min(1, 'Must be greater than 0')
    .required('Old Admin ID is required'),
  email: Yup.string()
    .email('Please enter a valid email format')
    .required('Email is required'),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('First name is required'),
  gender: Yup.string()
    .oneOf(['male', 'female'], 'Please select a valid gender')
    .required('Gender is required'),
  hashToken: Yup.string().required('Hash Token is required'),
  chargifyCustomerId: Yup.number().min(1, 'Must be greater than 0').nullable(),
  salesforceId: Yup.string().nullable()
})

const MemberFormDrawer = ({
  memberFormVisible,
  setMemberFormVisible,
  member
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const {
    memberships,
    _id,
    salesforceLastUpdate,
    salesforceError,
    mongoLastUpdate,
    createdAt,
    updatedAt,
    ...memberSanitized
  } = member

  const onClose = () => {
    setMemberFormVisible(false)
  }

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    id: '',
    hashToken: '',
    chargifyCustomerId: null,
    gender: '',
    mobile: '',
    postCode: '',
    screenName: '',
    salesforceId: null,
    ...memberSanitized
  }

  const handleFormSubmit = (values, { setSubmitting }) => {
    dispatch(
      updateMember(member._id, values, setSubmitting, setMemberFormVisible)
    )
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleFormSubmit
  })

  return (
    <ResponsiveDrawer
      title='Update Member'
      onClose={onClose}
      visible={memberFormVisible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Descriptions
          bordered
          column={1}
          className={classes.descriptionsWrapper}
        >
          <Descriptions.Item label='Email'>
            <TextInput
              classes={classes}
              id='email'
              disabled={true}
              formik={formik}
            />
          </Descriptions.Item>

          <Descriptions.Item label='First Name'>
            <TextInput classes={classes} id='firstName' formik={formik} />
          </Descriptions.Item>

          <Descriptions.Item label='Last Name'>
            <TextInput classes={classes} id='lastName' formik={formik} />
          </Descriptions.Item>

          <Descriptions.Item label='Hash Token'>
            <TextInput classes={classes} id='hashToken' formik={formik} />
          </Descriptions.Item>

          <Descriptions.Item label='Gender'>
            <SelectInput id='gender' formik={formik}>
              <MenuItem key='male' value='male'>
                Male
              </MenuItem>
              <MenuItem key='female' value='female'>
                Female
              </MenuItem>
            </SelectInput>
          </Descriptions.Item>

          <Descriptions.Item label='Mobile'>
            <TextInput classes={classes} id='mobile' formik={formik} />
          </Descriptions.Item>

          <Descriptions.Item label='Post Code'>
            <TextInput classes={classes} id='postCode' formik={formik} />
          </Descriptions.Item>

          <Descriptions.Item label='Screen Name'>
            <TextInput classes={classes} id='screenName' formik={formik} />
          </Descriptions.Item>

          <Descriptions.Item label='Old Admin ID'>
            <TextInput
              classes={classes}
              id='id'
              formik={formik}
              disabled={member.id}
            />
          </Descriptions.Item>

          <Descriptions.Item label='Chargify ID'>
            <TextInput
              classes={classes}
              id='chargifyCustomerId'
              formik={formik}
            />
          </Descriptions.Item>
        </Descriptions>
        <Row className='mt-3 justify-content-end'>
          <Col xs={12} md={3}>
            <Button
              className='w-100'
              variant='contained'
              color='primary'
              type='submit'
              disabled={formik.isSubmitting}
            >
              {formik.isSubmitting && <CircularProgress size={20} />}
              Save
            </Button>
          </Col>
          <Col xs={12} md={3}>
            <Button
              className='w-100'
              variant='outlined'
              type='button'
              disabled={formik.isSubmitting}
              onClick={onClose}
            >
              {formik.isSubmitting && <CircularProgress size={20} />}
              Cancel
            </Button>
          </Col>
        </Row>
      </form>
    </ResponsiveDrawer>
  )
}

const useStyles = makeStyles((theme) => ({
  descriptionsWrapper: {
    '& .ant-descriptions-item-content': {
      padding: 0,

      '& input': {
        padding: '3px 7px 7px'
      }
    }
  }
}))

MemberFormDrawer.propTypes = {
  member: PropTypes.object.isRequired,
  memberFormVisible: PropTypes.bool,
  setMemberFormVisible: PropTypes.func
}

export default MemberFormDrawer
