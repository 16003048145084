import {
  CANCELLATION_REPORT_FAIL,
  CANCELLATION_REPORT_REQUEST,
  CANCELLATION_REPORT_SUCCESS
} from '../constants/reports.constants'

export const reportsReducer = (
  state = { reports: [], loading: false },
  action
) => {
  const { type, payload } = action
  switch (type) {
    case CANCELLATION_REPORT_REQUEST:
      return { loading: true, reports: [] }
    case CANCELLATION_REPORT_SUCCESS:
      return {
        loading: false,
        reports: payload
      }
    case CANCELLATION_REPORT_FAIL:
      return {
        loading: false,
        reports: []
      }
    default:
      return state
  }
}
