import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row, Button } from 'react-bootstrap'
import { CircularProgress } from '@material-ui/core'
import { Divider } from 'antd'
import { makeStyles } from '@material-ui/core/styles'
import MemberFormDrawer from './MemberFormDrawer'
import {
  removeMemberData,
  updateMemberOnChargify,
  removeMemberDeleteRequest
} from '../../redux/actions/Members.actions'
import { setAlert } from '../../redux/actions/Alert.actions'
import { useHistory } from 'react-router-dom'
import EmailFormDrawer from './EmailFormDrawer'

const MemberActions = () => {
  const history = useHistory()
  const classes = useStyles()
  const dispatch = useDispatch()
  const {
    loadingUpdateChargify,
    loadingTransferMemberships,
    loadingDeleteMember,
    loadingRemoveDeleteRequest,
    data: member
  } = useSelector((state) => state.member)
  const [memberFormVisible, setMemberFormVisible] = useState(false)
  const [emailFormVisible, setEmailFormVisible] = useState(false)

  const handleMemberFormDrawer = (e) => {
    e.preventDefault()
    return setMemberFormVisible(true)
  }

  const handleEmailFormDrawer = (e) => {
    e.preventDefault()
    return setEmailFormVisible(true)
  }

  const handleUpdateOnChargify = (e) => {
    e.preventDefault()
    if (member.chargifyCustomerId) {
      dispatch(updateMemberOnChargify(member._id))
    } else {
      dispatch(
        setAlert('Please add a Chargify Customer ID before syncing', 'info')
      )
    }
  }

  const handleDeleteMember = (e) => {
    e.preventDefault()
    if (
      window.confirm('Are you sure you wish to remove all Member related data?')
    ) {
      dispatch(removeMemberData(member._id, history))
    } else {
      dispatch(setAlert('Action has been cancelled.', 'info'))
    }
  }

  const handleRemoveDeleteRequest = (e) => {
    e.preventDefault()
    if (
      window.confirm(
        'Are you sure you wish to remove the delete request for this member?'
      )
    ) {
      dispatch(removeMemberDeleteRequest(member._id, history))
    } else {
      dispatch(setAlert('Action has been cancelled.', 'info'))
    }
  }

  return (
    <>
      <MemberFormDrawer
        memberFormVisible={memberFormVisible}
        setMemberFormVisible={setMemberFormVisible}
        member={member}
      />

      <EmailFormDrawer
        emailFormVisible={emailFormVisible}
        setEmailFormVisible={setEmailFormVisible}
        member={member}
      />

      <Row style={{ padding: '0 10px 25px' }}>
        <Col xs={12}>
          <Divider
            orientation='left'
            style={{
              color: 'rgba(0, 0, 0, 0.3)',
              marginBottom: '10px'
            }}
          >
            Member options
          </Divider>
        </Col>
        <Col xs={12}>
          <Button
            variant='light'
            className={classes.buttonMenu}
            onClick={handleMemberFormDrawer}
          >
            Edit Member
          </Button>
          <Button
            variant='light'
            className={classes.buttonMenuDanger}
            title='Remove Delete Request'
            onClick={handleRemoveDeleteRequest}
            disabled={!member.requestedDeletionAt}
          >
            <div>
              Remove Delete Request
              {loadingRemoveDeleteRequest && <CircularProgress size={20} />}
            </div>
          </Button>
          <Button
            variant='light'
            className={classes.buttonMenu}
            title='Update member data into Chargify.'
            onClick={handleUpdateOnChargify}
          >
            <div>
              Update on Chargify
              {loadingUpdateChargify && <CircularProgress size={20} />}
            </div>
          </Button>
          <Button
            variant='light'
            className={classes.buttonMenu}
            title='Transfer Memberships to another Member'
            onClick={handleEmailFormDrawer}
            disabled={loadingTransferMemberships}
          >
            <div>
              Transfer Memberships
              {loadingTransferMemberships && <CircularProgress size={20} />}
            </div>
          </Button>
          <Button
            variant='light'
            className={classes.buttonMenuDanger}
            title='Remove Member Data'
            onClick={handleDeleteMember}
            disabled={loadingDeleteMember}
          >
            <div>
              Remove Member Data
              {loadingDeleteMember && <CircularProgress size={20} />}
            </div>
          </Button>
        </Col>
      </Row>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  buttonMenu: {
    width: '100%',
    textAlign: 'left',
    fontSize: '1rem',
    borderRadius: '1px',
    backgroundColor: 'transparent',
    border: '0',
    color: 'rgba(0, 0, 0, 0.7)',

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      color: 'rgba(0, 0, 0, 0.9)'
    },

    '& div': {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between'
    }
  },

  buttonMenuDanger: {
    width: '100%',
    textAlign: 'left',
    fontSize: '1rem',
    borderRadius: '1px',
    backgroundColor: 'transparent',
    border: '0',
    color: 'rgba(0, 0, 0, 0.7)',

    '& div': {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between'
    },

    '&:hover': {
      backgroundColor: 'rgba(221, 35, 18, 0.5)',
      color: 'rgba(255, 254, 254, 0.9)'
    }
  }
}))

export default MemberActions
