import React from 'react'
import PropTypes from 'prop-types'
import { Input, Button, Space } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

const TableSearch = ({
  searchInput,
  dataIndex,
  confirm,
  selectedKeys,
  setSelectedKeys,
  handleSearch,
  handleReset,
  setSearchState,
  clearFilters
}) => {
  return (
    <div style={{ padding: 8 }}>
      <Input
        ref={searchInput}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size='small'
        >
          Reset
        </Button>
        <Button
          type='link'
          size='small'
          onClick={() => {
            confirm({ closeDropdown: false })
            setSearchState({
              searchText: selectedKeys[0],
              searchedColumn: dataIndex
            })
          }}
        >
          Filter
        </Button>
      </Space>
    </div>
  )
}

TableSearch.propTypes = {
  searchInput: PropTypes.object.isRequired,
  dataIndex: PropTypes.string.isRequired,
  selectedKeys: PropTypes.array.isRequired,
  setSelectedKeys: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  setSearchState: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired
}

export default TableSearch
