import React from 'react'
import PropTypes from 'prop-types'

import { TextField } from '@material-ui/core'

const TextInput = ({ id, formik, classes, disabled }) => {
  return (
    <TextField
      size='small'
      fullWidth
      id={id}
      name={id}
      value={formik.values[id] || ''}
      onChange={formik.handleChange}
      error={formik.touched[id] && Boolean(formik.errors[id])}
      helperText={formik.touched[id] && formik.errors[id]}
      className={classes.input}
      disabled={disabled}
    />
  )
}

TextInput.propTypes = {
  id: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
  classes: PropTypes.object,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
}

export default TextInput
