import React, { useState, useEffect } from 'react'
import { Layout, Menu } from 'antd'
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  DashboardOutlined,
  FileTextOutlined
} from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import UseBreakpointConditions from '../_base/hooks/UseBreakpointConditions'

const { Sider } = Layout
const { Item } = Menu

function SidebarTemplate() {
  const [collapsed, setCollapsed] = useState(false)
  const [currentPath, setCurrentPath] = useState('/dashboard')
  const classes = useStyles({ collapsed })

  const { belowSm, belowMd } = UseBreakpointConditions()

  useEffect(() => {
    if (!collapsed && belowSm) document.body.style.overflow = 'hidden'
    else document.body.style.overflow = 'auto'
  }, [collapsed])

  // On window resize: automatically collapse/open sidebar:
  useEffect(() => {
    if (belowMd) setCollapsed(true)
    else setCollapsed(false)
  }, [belowMd])

  useEffect(() => {
    setCurrentPath(`/${window.location.pathname.split('/')[1]}`)
  }, [window.location.pathname])

  const toggleCollapsed = () => {
    setCollapsed(!collapsed)
  }

  const menuClickHandler = () => {
    if (!collapsed && belowMd) toggleCollapsed()
  }

  const collapseButton = () => {
    return (
      <div
        className='bg-white text-right p-1'
        style={{ width: `calc(100% + 25px)`, borderRadius: '3px' }}
      >
        {collapsed ? (
          <MenuUnfoldOutlined onClick={toggleCollapsed} />
        ) : (
          <MenuFoldOutlined onClick={toggleCollapsed} />
        )}
      </div>
    )
  }

  const sidebarClassName = collapsed
    ? `${classes.sidebar} ${classes.collapsedSidebar}`
    : `${classes.sidebar} ${classes.openSidebar}`

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      style={{ height: '100%' }}
      className={sidebarClassName}
    >
      {collapseButton()}
      <Menu
        mode='inline'
        selectedKeys={[currentPath]}
        defaultOpenKeys={[]}
        style={{ borderRight: 0, paddingTop: '10px' }}
        className={classes.menu}
        onClick={menuClickHandler}
      >
        <Item icon={<DashboardOutlined />} key='/dashboard'>
          <Link to='/dashboard'>Dashboard</Link>
        </Item>
        <Item icon={<UserOutlined />} key='/members'>
          <Link to='/members'>Members</Link>
        </Item>
        <Item icon={<UserOutlined />} key='/requests'>
          <Link to='/requests'>Deletion Requests</Link>
        </Item>
        <Item icon={<UserOutlined />} key='/pendings'>
          <Link to='/pendings'>Pendings</Link>
        </Item>
        <Item icon={<FileTextOutlined />} key='/reports'>
          <Link to='/reports'>Reports</Link>
        </Item>
      </Menu>
    </Sider>
  )
}

const useStyles = makeStyles((theme) => ({
  sidebar: {
    backgroundColor: 'white',
    paddingTop: '.3rem'
  },
  [theme.breakpoints.down('sm')]: {
    collapsedSidebar: {
      width: '50px!important',
      minWidth: '50px!important',
      flex: '0 0 50px!important',
      position: 'relative',
      transition: theme.transitions.create(['position'], {
        delay: theme.transitions.duration.shortest
      })
    },
    openSidebar: {
      position: 'absolute',
      zIndex: 9,
      borderRight: `1px solid ${theme.palette.greys.light}`
    }
  }
}))

export default SidebarTemplate
