import React from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Loader from '../shared/Loader'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const location = useLocation()
  const { isAuthenticated, loading, member } = useSelector(
    (state) => state.user
  )

  localStorage.setItem('redirectTo', `${location.pathname}${location.search}`)
  if (!isAuthenticated) {
    return <Redirect to='/login' />
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        loading ? (
          <Loader />
        ) : !isAuthenticated && !loading && member ? (
          <Redirect to='/login' />
        ) : !loading ? (
          <Component {...props}> {props.children} </Component>
        ) : null
      }
    />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.func,
  children: PropTypes.object
}

export default PrivateRoute
