import {
  USER_LOAD_FAIL,
  USER_LOAD_REQUEST,
  USER_LOAD_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS
} from '../constants/user.constants'

export const userReducer = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case USER_LOGIN_REQUEST:
    case USER_LOAD_REQUEST:
    case USER_LOGOUT_REQUEST:
      return { loading: true }
    case USER_LOGIN_SUCCESS:
    case USER_LOAD_SUCCESS:
      return {
        loading: false,
        member: payload,
        isAuthenticated: true
      }
    case USER_LOGIN_FAIL:
    case USER_LOAD_FAIL:
    case USER_LOGOUT_SUCCESS:
      return {
        loading: false,
        error: payload,
        isAuthenticated: false,
        member: {}
      }
    default:
      return state
  }
}
