import {
  GET_MEMBERSHIP_TYPES_REQUEST,
  GET_MEMBERSHIP_TYPES_SUCCESS,
  GET_MEMBERSHIP_TYPES_FAIL
} from '../constants/membershipTypes.constants'

export const membershipTypesReducer = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_MEMBERSHIP_TYPES_REQUEST:
      return { loading: true }
    case GET_MEMBERSHIP_TYPES_SUCCESS:
      return {
        loading: false,
        data: payload
      }
    case GET_MEMBERSHIP_TYPES_FAIL:
      return {
        loading: false
      }
    default:
      return state
  }
}
