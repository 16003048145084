import React from 'react'
import PropTypes from 'prop-types'
import { Button, CircularProgress, makeStyles } from '@material-ui/core'
import { Row } from 'react-bootstrap'

const ScheduledCancellationReportButton = ({
  fetchData,
  report,
  loading,
  reportFetched,
  setReportFetched
}) => {
  const classes = useStyles()
  const buttonLoading = loading && reportFetched?.title === report.title
  return (
    <Row className={classes.row}>
      <Button
        className={classes.button}
        size='small'
        color='primary'
        variant='outlined'
        disabled={loading}
        onClick={() => {
          setReportFetched(report)
          fetchData(report?.endpoint)
        }}
      >
        {buttonLoading ? (
          <CircularProgress
            color={'primary'}
            variant='indeterminate'
            size={20}
          />
        ) : (
          <span>Download</span>
        )}
      </Button>
      <span className={classes.title}>{report.title}</span>
    </Row>
  )
}

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    fontSize: '1rem'
  },
  button: {
    width: '100px',
    height: '2rem',
    marginTop: '.5rem',
    marginRight: '2rem'
  }
}))

ScheduledCancellationReportButton.propTypes = {
  fetchData: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string,
  setReportFetched: PropTypes.func.isRequired,
  reportFetched: PropTypes.object,
  report: PropTypes.object
}

export default ScheduledCancellationReportButton
