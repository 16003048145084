export const GET_MEMBERS_REQUEST = 'GET_MEMBERS_REQUEST'
export const GET_MEMBERS_SUCCESS = 'GET_MEMBERS_SUCCESS'
export const GET_MEMBERS_FAIL = 'GET_MEMBERS_FAIL'

export const GET_MEMBER_BY_ID_REQUEST = 'GET_MEMBER_BY_ID_REQUEST'
export const GET_MEMBER_BY_ID_SUCCESS = 'GET_MEMBER_BY_ID_SUCCESS'
export const GET_MEMBER_BY_ID_FAIL = 'GET_MEMBER_BY_ID_FAIL'

export const UPDATE_MEMBER_REQUEST = 'UPDATE_MEMBER_REQUEST'
export const UPDATE_MEMBER_SUCCESS = 'UPDATE_MEMBER_SUCCESS'
export const UPDATE_MEMBER_FAIL = 'UPDATE_MEMBER_FAIL'

export const RESET_MEMBER_SALESFORCE_ISSUE_REQUEST =
  'RESET_MEMBER_SALESFORCE_ISSUE_REQUEST'
export const RESET_MEMBER_SALESFORCE_ISSUE_SUCCESS =
  'RESET_MEMBER_SALESFORCE_ISSUE_SUCCESS'
export const RESET_MEMBER_SALESFORCE_ISSUE_FAIL =
  'RESET_MEMBER_SALESFORCE_ISSUE_FAIL'

export const UPDATE_MEMBER_ON_CHARGIFY_REQUEST =
  'UPDATE_MEMBER_ON_CHARGIFY_REQUEST'
export const UPDATE_MEMBER_ON_CHARGIFY_SUCCESS =
  'UPDATE_MEMBER_ON_CHARGIFY_SUCCESS'
export const UPDATE_MEMBER_ON_CHARGIFY_FAIL = 'UPDATE_MEMBER_ON_CHARGIFY_FAIL'

export const REMOVE_MEMBER_DATA_REQUEST = 'REMOVE_MEMBER_DATA_REQUEST'
export const REMOVE_MEMBER_DATA_SUCCESS = 'REMOVE_MEMBER_DATA_SUCCESS'
export const REMOVE_MEMBER_DATA_FAIL = 'REMOVE_MEMBER_DATA_FAIL'

export const UPDATE_MEMBER_MEMBERSHIPS_REQUEST =
  'UPDATE_MEMBER_MEMBERSHIPS_REQUEST'
export const UPDATE_MEMBER_MEMBERSHIPS_SUCCESS =
  'UPDATE_MEMBER_MEMBERSHIPS_SUCCESS'
export const UPDATE_MEMBER_MEMBERSHIPS_FAIL = 'UPDATE_MEMBER_MEMBERSHIPS_FAIL'

export const TRANSFER_MEMBERSHIPS_REQUEST = 'TRANSFER_MEMBERSHIPS_REQUEST'
export const TRANSFER_MEMBERSHIPS_SUCCESS = 'TRANSFER_MEMBERSHIPS_SUCCESS'
export const TRANSFER_MEMBERSHIPS_FAIL = 'TRANSFER_MEMBERSHIPS_FAIL'

export const REMOVE_DELETE_MEMBER_REQUEST = 'REMOVE_DELETE_MEMBER_REQUEST'
export const REMOVE_DELETE_MEMBER_SUCCESS = 'REMOVE_DELETE_MEMBER_SUCCESS'
export const REMOVE_DELETE_MEMBER_FAIL = 'REMOVE_DELETE_MEMBER_FAIL'
