import {
  GET_MEMBERS_FAIL,
  GET_MEMBERS_REQUEST,
  GET_MEMBERS_SUCCESS,
  GET_MEMBER_BY_ID_FAIL,
  GET_MEMBER_BY_ID_REQUEST,
  GET_MEMBER_BY_ID_SUCCESS,
  UPDATE_MEMBER_FAIL,
  UPDATE_MEMBER_REQUEST,
  UPDATE_MEMBER_SUCCESS,
  REMOVE_MEMBER_DATA_REQUEST,
  REMOVE_MEMBER_DATA_SUCCESS,
  REMOVE_MEMBER_DATA_FAIL,
  UPDATE_MEMBER_ON_CHARGIFY_REQUEST,
  UPDATE_MEMBER_ON_CHARGIFY_SUCCESS,
  UPDATE_MEMBER_ON_CHARGIFY_FAIL,
  UPDATE_MEMBER_MEMBERSHIPS_REQUEST,
  UPDATE_MEMBER_MEMBERSHIPS_SUCCESS,
  UPDATE_MEMBER_MEMBERSHIPS_FAIL,
  TRANSFER_MEMBERSHIPS_REQUEST,
  TRANSFER_MEMBERSHIPS_SUCCESS,
  TRANSFER_MEMBERSHIPS_FAIL,
  REMOVE_DELETE_MEMBER_REQUEST
} from '../constants/members.constants'

export const membersReducer = (state = { members: {} }, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_MEMBERS_REQUEST:
      return { loading: true, members: {} }
    case GET_MEMBERS_SUCCESS:
      return {
        loading: false,
        members: payload
      }
    case GET_MEMBERS_FAIL:
      return {
        loading: false,
        members: {}
      }
    default:
      return state
  }
}

export const memberReducer = (state = {}, action) => {
  const { type, payload } = action

  switch (type) {
    case GET_MEMBER_BY_ID_REQUEST:
      return { loading: true }
    case UPDATE_MEMBER_MEMBERSHIPS_REQUEST:
      return { ...state, loading: true }
    case UPDATE_MEMBER_REQUEST:
      return { ...state, loadingUpdate: true }
    case REMOVE_MEMBER_DATA_REQUEST: {
      return { ...state, loadingDeleteMember: true }
    }
    case REMOVE_DELETE_MEMBER_REQUEST: {
      return { ...state, loadingRemoveDeleteRequest: true }
    }
    case TRANSFER_MEMBERSHIPS_REQUEST: {
      return { ...state, loadingTransferMemberships: true }
    }
    case UPDATE_MEMBER_ON_CHARGIFY_REQUEST:
      return { ...state, loadingUpdateChargify: true }
    case GET_MEMBER_BY_ID_SUCCESS:
    case UPDATE_MEMBER_SUCCESS:
    case UPDATE_MEMBER_MEMBERSHIPS_SUCCESS:
    case TRANSFER_MEMBERSHIPS_SUCCESS:
    case UPDATE_MEMBER_ON_CHARGIFY_SUCCESS:
      return {
        loading: false,
        loadingUpdate: false,
        loadingTransferMemberships: false,
        loadingUpdateChargify: false,
        data: payload
      }
    case REMOVE_MEMBER_DATA_SUCCESS: {
      return {
        loadingDeleteMember: false,
        data: {}
      }
    }
    case REMOVE_MEMBER_DATA_FAIL:
    case GET_MEMBER_BY_ID_FAIL:
    case UPDATE_MEMBER_FAIL:
    case UPDATE_MEMBER_MEMBERSHIPS_FAIL:
    case TRANSFER_MEMBERSHIPS_FAIL:
    case UPDATE_MEMBER_ON_CHARGIFY_FAIL:
      return {
        ...state,
        loading: false,
        loadingDeleteMember: false,
        loadingUpdate: false,
        loadingUpdateChargify: false,
        loadingTransferMemberships: false
      }
    default:
      return state
  }
}
