import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Descriptions } from 'antd'
import { Alert, Col, Row } from 'react-bootstrap'
import { Button, CircularProgress, MenuItem } from '@material-ui/core/'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import { updatePaymentOptions } from '../../redux/actions/Memberships.actions'

import ResponsiveDrawer from '../../shared/ResponsiveDrawer'
import SelectInput from '../../shared/form/SelectInput'
import TextInput from '../../shared/form/TextInput'

const paymentMethods = [
  {
    name: 'Apple',
    value: 'apple'
  },
  {
    name: 'Cash',
    value: 'cash'
  },
  {
    name: 'Chargify',
    value: 'chargify'
  },
  {
    name: 'Credit Card',
    value: 'credit card'
  },
  {
    name: 'Free',
    value: 'free'
  },
  {
    name: 'Node',
    value: 'node'
  },
  {
    name: 'Other',
    value: 'other'
  },
  {
    name: 'Paypal',
    value: 'paypal'
  },
  {
    name: 'Voucher',
    value: 'voucher'
  }
]

const validationSchema = Yup.object({
  paymentMethod: Yup.string().required('Payment Method is required'),
  chargifySite: Yup.string().when('paymentMethod', {
    is: 'chargify',
    then: Yup.string().required('Chargify Site is required')
  }),
  chargifySubscriptionId: Yup.string().when('paymentMethod', {
    is: 'chargify',
    then: Yup.string().required('Chargify ID is required')
  })
})

const UpdatePaymentInformationFormDrawer = ({
  paymentMethodFormVisible,
  setPaymentMethodFormVisible,
  memberId,
  membership
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const envChargifySite = process.env.REACT_APP_CHARGIFY_SITE
  const envChargifyVoomeSite =
    envChargifySite === 'michelle-bridges' && 'voome-weekly'
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    membership.paymentMethod
  )

  const onClose = () => {
    setPaymentMethodFormVisible(false)
  }

  const initialValues = {
    paymentMethod: membership.paymentMethod,
    chargifySite: membership.chargify?.site || envChargifySite,
    chargifySubscriptionId: membership.chargify?.subscriptionId || ''
  }

  const handlePaymentMethodChange = (e, formik) => {
    e.preventDefault()
    setSelectedPaymentMethod(e.target.value)
    formik.handleChange(e)
  }

  const handleChargifySiteChange = (e, formik) => {
    e.preventDefault()
    formik.handleChange(e)
  }

  const handleFormSubmit = (values, { setSubmitting, errors }) => {
    dispatch(
      updatePaymentOptions({
        memberId,
        membershipId: membership._id,
        values,
        setSubmitting,
        setPaymentMethodFormVisible
      })
    )
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleFormSubmit
  })

  return (
    <ResponsiveDrawer
      title='Update Payment Method'
      onClose={onClose}
      visible={paymentMethodFormVisible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Alert variant='info'>
        Changing the payment method can cause a loss of data of the previous
        selection.
      </Alert>
      <form onSubmit={formik.handleSubmit}>
        <Descriptions
          bordered
          className={classes.descriptionsWrapper}
          column={1}
        >
          <Descriptions.Item label='Payment Method'>
            <SelectInput
              classes={classes}
              id='paymentMethod'
              formik={formik}
              onChange={(e) => handlePaymentMethodChange(e, formik)}
            >
              {paymentMethods.map((pm) => (
                <MenuItem key={pm.value} value={pm.value}>
                  {pm.name}
                </MenuItem>
              ))}
            </SelectInput>
          </Descriptions.Item>
          {selectedPaymentMethod === 'chargify' && (
            <>
              <Descriptions.Item label='Chargify Site'>
                <SelectInput
                  classes={classes}
                  id='chargifySite'
                  formik={formik}
                  onChange={(e) => handleChargifySiteChange(e, formik)}
                >
                  <MenuItem key={envChargifySite} value={envChargifySite}>
                    {envChargifySite}
                  </MenuItem>
                  {envChargifyVoomeSite && (
                    <MenuItem
                      key={envChargifyVoomeSite}
                      value={envChargifyVoomeSite}
                    >
                      {envChargifyVoomeSite}
                    </MenuItem>
                  )}
                </SelectInput>
              </Descriptions.Item>
              <Descriptions.Item label='Chargify Subscription ID'>
                <TextInput
                  classes={classes}
                  id='chargifySubscriptionId'
                  formik={formik}
                />
              </Descriptions.Item>
            </>
          )}
        </Descriptions>
        <Row className='mt-3 justify-content-end'>
          <Col xs={12} md={3}>
            <Button
              className='w-100'
              variant='contained'
              color='primary'
              type='submit'
              disabled={formik.isSubmitting}
            >
              {formik.isSubmitting && <CircularProgress size={20} />}
              Save
            </Button>
          </Col>
          <Col xs={12} md={3}>
            <Button
              className='w-100'
              variant='outlined'
              type='button'
              disabled={formik.isSubmitting}
              onClick={onClose}
            >
              {formik.isSubmitting && <CircularProgress size={20} />}
              Cancel
            </Button>
          </Col>
        </Row>
      </form>
    </ResponsiveDrawer>
  )
}

const useStyles = makeStyles((theme) => ({
  descriptionsWrapper: {
    '& .ant-descriptions-item-label': {
      width: '33%'
    },

    '& .ant-descriptions-item-content': {
      padding: 0,

      '& input': {
        padding: '3px 7px 7px'
      }
    }
  },
  input: {
    '& .MuiInput-underline:before': {
      borderBottomColor: 'transparent'
    },
    '& .MuiInput-underline:hover': {
      borderBottomColor: 'rgba(0, 0, 0, 0.3)'
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: 'rgba(0, 0, 0, 0.3)'
    }
  }
}))

UpdatePaymentInformationFormDrawer.propTypes = {
  paymentMethodFormVisible: PropTypes.bool,
  setPaymentMethodFormVisible: PropTypes.func,
  memberId: PropTypes.string.isRequired,
  membership: PropTypes.object.isRequired
}

export default UpdatePaymentInformationFormDrawer
