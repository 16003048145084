import { v4 as uuid } from 'uuid'
import { SET_ALERT, REMOVE_ALERT } from '../constants/alert.constants'

export const setAlert = (msg, alertType) => (dispatch) => {
  const id = uuid()

  const type = alertType || 'danger'

  dispatch({
    type: SET_ALERT,
    payload: { msg, type, id }
  })

  setTimeout(() => {
    dispatch({
      type: REMOVE_ALERT,
      payload: id
    })
  }, 5000)
}

export const dismissAlert = (id) => (dispatch) => {
  dispatch({
    type: REMOVE_ALERT,
    payload: id
  })
}
