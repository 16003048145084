import axios from '../AxiosWrapper'
import setAuthToken from '../../_base/helpers/AuthTokenHelper'
import {
  USER_LOAD_FAIL,
  USER_LOAD_REQUEST,
  USER_LOAD_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS
} from '../constants/user.constants'

// Load User
export const loadUser = () => async (dispatch) => {
  dispatch({
    type: USER_LOAD_REQUEST
  })

  if (localStorage.token) {
    setAuthToken(localStorage.token)
  }
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_HOST}auth/refresh`,
      {},
      config
    )
    dispatch({
      type: USER_LOAD_SUCCESS,
      payload: res.data
    })
  } catch (error) {
    dispatch({
      type: USER_LOAD_FAIL
    })
  }
}

// Login User
export const login = ({ email, password }) => async (dispatch) => {
  dispatch({
    type: USER_LOGIN_REQUEST
  })

  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_HOST}auth/login`,
      { email, password },
      config
    )

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: res.data
    })
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL
    })
  }
}

// Logout
export const logout = () => async (dispatch) => {
  dispatch({
    type: USER_LOGOUT_REQUEST
  })

  try {
    await axios.get(`${process.env.REACT_APP_API_HOST}api/v1/logout`)

    dispatch({
      type: USER_LOGOUT_SUCCESS
    })
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL
    })
  }
}
