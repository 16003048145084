import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'

import * as Components from '../components/login'

const LoginScreen = () => {
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated)

  // Redirect if logged in
  if (isAuthenticated) {
    const redirect = localStorage.getItem('redirectTo') || '/dashboard'
    return <Redirect to={redirect} />
  }

  return (
    <>
      <Components.LeftPanel />
      <Components.RightPanel />
    </>
  )
}

export default LoginScreen
