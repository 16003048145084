import React, { useState } from 'react';
import PropTypes from 'prop-types';
import BaseTextField from './BaseTextField';
import { IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const PasswordField = ({ ...props }) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <BaseTextField
      {...props}
      type={values.showPassword ? 'text' : 'password'}
      inputProps={{
        endAdornment: (
          <IconButton
            aria-label='toggle password visibility'
            className={classes.icon}
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
          >
            {values.showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        ),
      }}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  icon: {
    padding: 0,
  },
}));

PasswordField.propTypes = {
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  autofocus: PropTypes.bool,
};

PasswordField.defaultProps = {
  required: true,
  fullWidth: true,
  name: 'textField',
  label: 'Text Field',
  autofocus: false,
  type: 'text',
};

export default PasswordField;
