import React from 'react'
import PropTypes from 'prop-types'

const MembershipsRoundTag = ({ memberships }) => {
  if (memberships) {
    const lastMembership = memberships.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    )[0]
    if (lastMembership?.subs) {
      const lastSubscription = lastMembership.subs.sort(
        (a, b) => parseInt(b.roundId) - parseInt(a.roundId)
      )[0]
      return <span>{lastSubscription?.roundId || ' - '}</span>
    }
  }
  return <span> - </span>
}

MembershipsRoundTag.propTypes = {
  memberships: PropTypes.array.isRequired
}

export default MembershipsRoundTag
