/* eslint-disable react/display-name */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card } from 'react-bootstrap'
import { Skeleton, Tabs, Table, Tag, Divider } from 'antd'
import { Grid, Button } from '@material-ui/core'
import { Link } from 'react-router-dom'
import ComponentLoader from '../../shared/ComponentLoader'
import StatusTag from './StatusTag.js'
import { syncMemberships } from '../../redux/actions/Members.actions'

const { TabPane } = Tabs

const getActionLinks = (_id, member) => {
  return (
    <>
      <Divider type='vertical' />
      <Link
        to={`/members/${member._id}/memberships/${_id}`}
        style={{ textDecoration: 'underline' }}
      >
        view
      </Link>
      <Divider type='vertical' />
    </>
  )
}

const MemberDataTabs = () => {
  const dispatch = useDispatch()
  const { loading, data: member } = useSelector((state) => state.member)
  const pagination = {
    pageSize: 5
  }

  const handleSyncMembershipsButton = (e) => {
    e.preventDefault()
    dispatch(
      syncMemberships(member._id)
    )
  }

  const membershipsColumns = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => a?.status - b?.status,
      sortDirections: ['descend', 'ascend'],
      render: (s) => <StatusTag status={s} />
    },
    {
      title: 'Payment Plan',
      dataIndex: 'paymentPlan',
      key: 'paymentPlan',
      render: (p) => p?.name
    },
    {
      title: 'Payment Method',
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      render: (p) => <Tag color='default'>{p}</Tag>
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => new Date(a?.createdAt) - new Date(b?.createdAt),
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'descend',
      render: (c) => new Date(c).toLocaleString()
    },
    {
      title: '',
      dataIndex: '_id',
      key: '_id',
      render: (_id) => getActionLinks(_id, member)
    }
  ]

  return (
    <Card style={{ color: 'rgba(0, 0, 0, 0.7)' }}>
      <Card.Body>
        {loading ? (
          <ComponentLoader />
        ) : (
          member && (
            <Skeleton loading={loading} active>
              <Grid item style={{ display: 'flex', justifyContent: 'flex-end' ,marginTop: '1rem', marginBottom:'1rem' }}>
                <Button size='small' color='primary' variant='outlined' onClick={handleSyncMembershipsButton}>
                  Sync Memberships
                </Button>
              </Grid>
              <Tabs type='card'>
                <TabPane tab='Memberships' key='memberships'>
                  <Table
                    style={{ overflowX: 'scroll' }}
                    rowKey={(record) => record._id}
                    columns={membershipsColumns}
                    dataSource={member.memberships}
                    pagination={pagination}
                  />
                </TabPane>
              </Tabs>
            </Skeleton>
          )
        )}
      </Card.Body>
    </Card>
  )
}

export default MemberDataTabs
