import React from 'react'
import PropTypes from 'prop-types'
import { Tag } from 'antd'

const MembershipsTag = ({ memberships }) => {
  if (memberships.length === 0) {
    return <Tag color='red'>No Membership found</Tag>
  }

  const lastMembership = memberships.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  )[0]

  if (lastMembership?.billingOption === 'L') {
    return <Tag color='blue'>Lifestyle</Tag>
  }

  if (lastMembership?.status === 'pending') {
    return <Tag color='red'>Membership on pending</Tag>
  }

  if (lastMembership?.billingOption !== 'L') {
    return <Tag color='cyan'>Single Round</Tag>
  }
}

MembershipsTag.propTypes = {
  memberships: PropTypes.array
}

export default MembershipsTag
