import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import { TextField } from 'formik-material-ui'
import { makeStyles } from '@material-ui/core/styles'

const BaseTextField = ({
  required,
  fullWidth,
  name,
  label,
  type,
  autofocus,
  inputProps
}) => {
  const classes = useStyles()

  return (
    <Field
      component={TextField}
      margin='normal'
      required={required}
      fullWidth={fullWidth}
      id={name}
      label={label}
      name={name}
      autoComplete={name}
      type={type}
      autoFocus={autofocus}
      className={classes.spacing}
      InputProps={inputProps}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  spacing: {
    margin: theme.spacing(3, 0, 2)
  }
}))

BaseTextField.propTypes = {
  inputProps: PropTypes.object,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  autofocus: PropTypes.bool
}

BaseTextField.defaultProps = {
  required: true,
  fullWidth: true,
  name: 'textField',
  label: 'Text Field',
  autofocus: false,
  type: 'text'
}

export default BaseTextField
