import React from 'react'
import PropTypes from 'prop-types'
import { Drawer } from 'antd'
import UseBreakpointConditions from '../_base/hooks/UseBreakpointConditions'

const ResponsiveDrawer = ({ children, ...props }) => {
  const { belowSm, belowMd } = UseBreakpointConditions()
  let drawerWidth = 720
  if (belowMd) drawerWidth = '65%'
  if (belowSm) drawerWidth = '82%'
  return (
    <Drawer width={drawerWidth} {...props}>
      {children}
    </Drawer>
  )
}

ResponsiveDrawer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  props: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
}

export default ResponsiveDrawer
