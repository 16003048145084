import React from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, Grid } from '@material-ui/core'

const ComponentLoader = ({ color }) => {
  return (
    <Grid container direction='row' justify='center' alignItems='center' style={{ height: '100%', padding: '4px 0' }}>
      <Grid item>
        <CircularProgress color={color || 'secondary'} variant='indeterminate' size={40}></CircularProgress>
      </Grid>
    </Grid>
  )
}

ComponentLoader.propTypes = {
  color: PropTypes.string
}

export default ComponentLoader
