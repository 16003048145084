import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Container, Row } from 'react-bootstrap'
import * as cp from '../components/members'
import * as Components from '../components/memberships'
import { getMemberById } from '../redux/actions/Members.actions'
import { getMembershipByID } from '../redux/actions/Memberships.actions'
import { getMembershipTypes } from '../redux/actions/MembershipTypes.actions'

function MembershipDetailsPage() {
  const { memberId, membershipId } = useParams()
  const dispatch = useDispatch()

  const { loading: loadingMember, data: member } = useSelector(
    (state) => state.member
  )
  const { loading, data: membership } = useSelector((state) => state.membership)
  const {
    loading: loadingMembershipTypes,
    data: membershipTypes
  } = useSelector((state) => state.membershipTypes)

  useEffect(() => {
    if (!loadingMember && (!member || member._id !== memberId)) {
      dispatch(getMemberById(memberId))
    }
  }, [dispatch, member])

  useEffect(() => {
    if (!loading && (!membership || membership._id !== membershipId)) {
      dispatch(getMembershipByID(memberId, membershipId))
    }
  }, [dispatch, membership, member])

  useEffect(() => {
    if (!loadingMembershipTypes && !membershipTypes) {
      dispatch(getMembershipTypes())
    }
  }, [dispatch, membership, member])

  return (
    <Container fluid>
      <Row>
        <Col xs={12} md={3}>
          <cp.MemberActionCard memberships />
        </Col>
        <Col xs={12} md={9}>
          <Components.MembershipGeneralInformation />
          <Components.MembershipInformation />
          <Components.ChargifyInformation />
          <Components.MembershipCancellation />
          <Components.MembershipDataTabs />
        </Col>
      </Row>
    </Container>
  )
}

export default MembershipDetailsPage
