import React from 'react'
import PropTypes from 'prop-types'
import StatusTag from './StatusTag.js'

const MembershipStatusTag = ({ memberships }) => {
  if (memberships) {
    const lastMembership = memberships.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    )[0]
    if (lastMembership?.status) {
      return <StatusTag status={lastMembership.status} />
    }
    return <span> - </span>
  }
}

MembershipStatusTag.propTypes = {
  memberships: PropTypes.array.isRequired
}

export default MembershipStatusTag
