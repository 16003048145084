import React from 'react'
import { Card } from 'react-bootstrap'

import PropTypes from 'prop-types'

const ResponsiveCard = ({ children, style }) => {
  return (
    <Card
      style={{
        color: 'rgba(0, 0, 0, 0.7)',
        ...style
      }}
      className='mb-1 mb-lg-4 mb-md-3 mb-sm-2'
    >
      <Card.Body>{children}</Card.Body>
    </Card>
  )
}

ResponsiveCard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  style: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
}

export default ResponsiveCard
