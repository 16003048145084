import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row, Button } from 'react-bootstrap'
import { makeStyles } from '@material-ui/core/styles'
import UpdatePaymentMethodFormDrawer from './UpdatePaymentInformationFormDrawer'
import { Divider } from 'antd'
import AddSubscriptionFormDrawer from './AddSubscriptionFormDrawer'
import ChangePaymentPlanFormDrawer from './ChangePaymentPlanFormDrawer'
import {
  deleteMembership,
  revokeCancellation,
  syncMembershipWithChargify
} from '../../redux/actions/Memberships.actions'
import { CircularProgress } from '@material-ui/core'
import CancelMembershipDrawer from './CancelMembershipDrawer'

const MembershipActions = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { data: member } = useSelector((state) => state.member)
  const {
    data: membership,
    loadingChargifySync,
    loadingCancellationDeletion,
    cancelled
  } = useSelector((state) => state.membership)
  const { data: membershipTypes } = useSelector(
    (state) => state.membershipTypes
  )
  const [paymentMethodFormVisible, setPaymentMethodFormVisible] = useState(
    false
  )
  const [addSubscriptionFormVisible, setAddSubscriptionFormVisible] = useState(
    false
  )
  const [
    cancelMembershipDrawerVisible,
    setCancelMembershipDrawerVisible
  ] = useState(false)
  const [
    changePaymentPlanFormVisible,
    setChangePaymentPlanFormVisible
  ] = useState(false)

  const lastCancellation = membership?.cancellations
    ? membership?.cancellations[membership?.cancellations.length - 1]
    : null

  useEffect(() => {
    if (cancelled) window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [cancelled])

  const handleUpdatePaymentOptionsFormDrawer = (e) => {
    e.preventDefault()
    return setPaymentMethodFormVisible(true)
  }

  const handleAddSubscriptionFormDrawer = (e) => {
    e.preventDefault()
    return setAddSubscriptionFormVisible(true)
  }

  const handleChangePaymentPlanFormDrawer = (e) => {
    e.preventDefault()
    return setChangePaymentPlanFormVisible(true)
  }

  const handleSyncWithChargifyButton = (e) => {
    e.preventDefault()
    dispatch(
      syncMembershipWithChargify({
        memberId: member._id,
        membershipId: membership._id
      })
    )
  }

  const handleDeleteMembershipButton = (e) => {
    e.preventDefault()
    if (!window.confirm('Are you sure you want to delete this membership?'))
      return
    dispatch(
      deleteMembership({ memberId: member._id, membershipId: membership._id })
    )
  }

  const handleCancelMembership = () => {
    setCancelMembershipDrawerVisible(true)
  }

  const handleRevokeCancellation = () => {
    if (loadingCancellationDeletion) return
    if (
      !window.confirm(
        'Are you sure you want to revoke/reactivate the cancellation?'
      )
    )
      return
    dispatch(
      revokeCancellation(member?._id, membership?._id, lastCancellation?._id)
    )
  }

  const scheduledToCancel = membership?.scheduledCancelDate

  const possibilityToCancel = false

  return (
    <>
      {membership && (
        <>
          <UpdatePaymentMethodFormDrawer
            paymentMethodFormVisible={paymentMethodFormVisible}
            setPaymentMethodFormVisible={setPaymentMethodFormVisible}
            memberId={member._id}
            membership={membership}
          />
          <AddSubscriptionFormDrawer
            addSubscriptionFormVisible={addSubscriptionFormVisible}
            setAddSubscriptionFormVisible={setAddSubscriptionFormVisible}
            memberId={member._id}
            membership={membership}
          />
          <CancelMembershipDrawer
            drawerVisible={cancelMembershipDrawerVisible}
            setDrawerVisible={setCancelMembershipDrawerVisible}
            memberId={member._id}
            membership={membership}
          />
          {membershipTypes && (
            <ChangePaymentPlanFormDrawer
              changePaymentPlanFormVisible={changePaymentPlanFormVisible}
              setChangePaymentPlanFormVisible={setChangePaymentPlanFormVisible}
              memberId={member._id}
              membership={membership}
            />
          )}
        </>
      )}
      <Row style={{ padding: 0 }}>
        <Col xs={12}>
          <Divider
            orientation='left'
            style={{
              color: 'rgba(0, 0, 0, 0.3)',
              marginBottom: '10px'
            }}
          >
            Membership options
          </Divider>
        </Col>
        <Col>
          <Button
            variant='light'
            className={classes.buttonMenu}
            onClick={handleUpdatePaymentOptionsFormDrawer}
          >
            Update Payment Options
          </Button>
          <Button
            variant='light'
            className={classes.buttonMenu}
            onClick={handleAddSubscriptionFormDrawer}
          >
            Add New Subscription
          </Button>
          {scheduledToCancel && (
            <Button
              variant='light'
              className={classes.buttonMenu}
              onClick={handleRevokeCancellation}
              disabled={loadingCancellationDeletion}
            >
              Revoke Cancellation
              {loadingCancellationDeletion && (
                <CircularProgress size={20} className={classes.loader} />
              )}
            </Button>
          )}
          <Button
            variant='light'
            className={classes.buttonMenu}
            onClick={handleChangePaymentPlanFormDrawer}
          >
            Change Payment Plan
          </Button>
          {possibilityToCancel && (
            <Button
              variant='light'
              className={classes.buttonMenu}
              onClick={handleCancelMembership}
            >
              Cancel Membership
            </Button>
          )}
          <Button
            variant='light'
            className={classes.buttonMenu}
            title='Force the sync with Chargify: This can help when a billing history is not updated'
            onClick={handleSyncWithChargifyButton}
          >
            <div>
              Sync with Chargify
              {loadingChargifySync && <CircularProgress size={10} />}
            </div>
          </Button>
          <Button
            variant='light'
            className={classes.buttonMenu}
            title='It will delete this membership: Only valid for the pending ones.'
            onClick={handleDeleteMembershipButton}
          >
            <div>
              Delete Membership
              {loadingChargifySync && <CircularProgress size={10} />}
            </div>
          </Button>
        </Col>
      </Row>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  buttonMenu: {
    width: '100%',
    textAlign: 'left',
    fontSize: '1rem',
    borderRadius: '1px',
    backgroundColor: 'transparent',
    border: '0',
    color: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    alignItems: 'center',

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      color: 'rgba(0, 0, 0, 0.9)'
    },

    '& div': {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between'
    }
  },
  loader: {
    marginLeft: '.5rem'
  }
}))

export default MembershipActions
