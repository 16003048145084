import React from 'react'
import PropTypes from 'prop-types'
import { Select, FormHelperText, makeStyles } from '@material-ui/core'

const SelectInput = ({ id, formik, children, onChange }) => {
  const classes = useStyles()
  return (
    <div>
      <Select
        size='small'
        fullWidth
        id={id}
        name={id}
        value={formik.values[id]}
        onChange={onChange || formik.handleChange}
        error={formik.touched[id] && Boolean(formik.errors[id])}
        // helperText={formik.touched[id] && formik.errors[id]}
        className={classes.input}
      >
        {children}
      </Select>
      {formik.touched[id] && Boolean(formik.errors[id]) && (
        <FormHelperText className={classes.errorText}>
          {formik.errors[id]}
        </FormHelperText>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  input: {
    '& .MuiInput-underline:before': {
      borderBottomColor: 'transparent'
    },
    '& .MuiInput-underline:hover': {
      borderBottomColor: 'rgba(0, 0, 0, 0.3)'
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: 'rgba(0, 0, 0, 0.3)'
    }
  },
  errorText: {
    color: theme.palette.error.main
  }
}))

SelectInput.propTypes = {
  id: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
  classes: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onChange: PropTypes.func
}

export default SelectInput
