import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import * as Components from '../components/reports'

function ReportsPage() {
  return (
    <Container
      fluid
      style={{
        backgroundColor: 'white',
        justifyContent: 'center',
        padding: '1.5rem',
        marginTop: '1.5rem',
        paddingRight: '.5rem'
      }}
    >
      <Row>
        <Col xs={12}>
          <Components.ScheduledCancellationsReport />
        </Col>
      </Row>
    </Container>
  )
}
export default ReportsPage
