import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { makeStyles } from '@material-ui/core/styles'

import LoginFormWrapper from './LoginFormWrapper'
import Logo12WBT from '../../_base/assets/img/12wbtlogo.png'

const RightPanel = () => {
  const classes = useStyles()

  return (
    <Col
      xs={12}
      md={5}
      className='d-flex flex-column justify-content-between text-center p-5'
    >
      <Row>
        <Col>
          <img src={Logo12WBT} alt='Logo' className={classes.logo} />
        </Col>
      </Row>
      <Row>
        <Col>
          <h4>Sign in</h4>
          <LoginFormWrapper />
        </Col>
      </Row>
      <Row>
        <Col>
          <small>12WBT &copy; {new Date().getFullYear()}</small>
        </Col>
      </Row>
    </Col>
  )
}

const useStyles = makeStyles((theme) => ({
  logo: {
    maxWidth: '150px'
  },
  [theme.breakpoints.down('sm')]: {
    logo: {
      maxWidth: '150px'
    }
  }
}))

export default RightPanel
