import React from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Descriptions } from 'antd'
import { Col, Row, Alert } from 'react-bootstrap'
import { Button, CircularProgress } from '@material-ui/core/'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import { addSubscriptionToMembership } from '../../redux/actions/Memberships.actions'
import ResponsiveDrawer from '../../shared/ResponsiveDrawer'
import TextInput from '../../shared/form/TextInput'

const validationSchema = Yup.object({
  roundId: Yup.string().required('Round ID is required')
})

const AddSubscriptionFormDrawer = ({
  addSubscriptionFormVisible,
  setAddSubscriptionFormVisible,
  memberId,
  membership
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const onClose = () => {
    setAddSubscriptionFormVisible(false)
  }

  const initialValues = {
    roundId: ''
  }

  const handleFormSubmit = (values, { setSubmitting, errors }) => {
    dispatch(
      addSubscriptionToMembership({
        memberId,
        membershipId: membership._id,
        values,
        setSubmitting,
        setAddSubscriptionFormVisible
      })
    )
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleFormSubmit
  })

  return (
    <ResponsiveDrawer
      title='Add new subscription'
      onClose={onClose}
      visible={addSubscriptionFormVisible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Alert variant='info'>
        When a new subscription is added using this form, it&lsquo;ll be created
        on our old Ruby API as well to keep compatibility with the old App
      </Alert>
      <form onSubmit={formik.handleSubmit}>
        <Descriptions
          bordered
          className={classes.descriptionsWrapper}
          column={1}
        >
          <Descriptions.Item label='Round ID'>
            <TextInput classes={classes} id='roundId' formik={formik} />
          </Descriptions.Item>
        </Descriptions>
        <Row className='mt-3 justify-content-end'>
          <Col xs={12} md={3}>
            <Button
              className='w-100'
              variant='contained'
              color='primary'
              type='submit'
              disabled={formik.isSubmitting}
            >
              {formik.isSubmitting && <CircularProgress size={20} />}
              Save
            </Button>
          </Col>
          <Col xs={12} md={3}>
            <Button
              className='w-100'
              variant='outlined'
              type='button'
              disabled={formik.isSubmitting}
              onClick={onClose}
            >
              {formik.isSubmitting && <CircularProgress size={20} />}
              Cancel
            </Button>
          </Col>
        </Row>
      </form>
    </ResponsiveDrawer>
  )
}

const useStyles = makeStyles((theme) => ({
  descriptionsWrapper: {
    '& .ant-descriptions-item-label': {
      width: '33%'
    },

    '& .ant-descriptions-item-content': {
      padding: 0,

      '& input': {
        padding: '3px 7px 7px'
      }
    }
  },
  input: {
    '& .MuiInput-underline:before': {
      borderBottomColor: 'transparent'
    },
    '& .MuiInput-underline:hover': {
      borderBottomColor: 'rgba(0, 0, 0, 0.3)'
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: 'rgba(0, 0, 0, 0.3)'
    }
  }
}))

AddSubscriptionFormDrawer.propTypes = {
  addSubscriptionFormVisible: PropTypes.bool,
  setAddSubscriptionFormVisible: PropTypes.func.isRequired,
  memberId: PropTypes.string.isRequired,
  membership: PropTypes.object.isRequired
}

export default AddSubscriptionFormDrawer
