import axios from '../../redux/AxiosWrapper'

const setAuthToken = (token) => {
  axios.defaults.withCredentials = true
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
  } else {
    delete axios.defaults.headers.common.Authorization
  }
}

export default setAuthToken
