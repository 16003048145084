import React from 'react'
import PropTypes from 'prop-types'
import { Layout } from 'antd'

import Header from './Header'
import Sidebar from './Sidebar'
import Breadcrumb from './Breadcrumb'
import Alert from '../shared/Alert'

const { Content } = Layout

const LayoutTemplate = ({ children }) => {
  return (
    <Layout>
      <Header />
      <Layout>
        <Sidebar />
        <Layout className='p-1 p-lg-4 p-md-3 p-sm-1'>
          <Breadcrumb />
          <Alert />
          <Content className='p-lg-4 p-md-3 p-sm-0 p-0'>{children}</Content>
        </Layout>
      </Layout>
    </Layout>
  )
}

LayoutTemplate.propTypes = {
  children: PropTypes.object
}

export default LayoutTemplate
