import React from 'react'
import { useSelector } from 'react-redux'
import { Skeleton, Descriptions } from 'antd'
import ResponsiveCard from '../../shared/ResponsiveCard'
import moment from 'moment'

const ChargifyInformation = () => {
  const { loading, data: membership } = useSelector((state) => state.membership)

  const chargifySubscriptionUrl = membership?.chargify
    ? `https://${membership.chargify.site}.chargify.com/subscriptions/${membership.chargify.subscriptionId}`
    : ''

  return (
    <>
      {membership?.chargify && (
        <ResponsiveCard>
          <Skeleton loading={loading} active>
            <Descriptions title='Chargify Information' bordered column={2}>
              <Descriptions.Item label='State'>
                {membership.chargify.state}
              </Descriptions.Item>
              <Descriptions.Item label='Chargify Site'>
                {membership.chargify.site}
              </Descriptions.Item>
              <Descriptions.Item label='Total Revenue'>
                {membership.chargify.totalRevenueInCents
                  ? `$ ${(
                      parseInt(membership.chargify.totalRevenueInCents) / 100
                    ).toFixed(2)}`
                  : '$ --'}
              </Descriptions.Item>
              <Descriptions.Item label='Coupon Code'>
                {membership.chargify.couponCode}
              </Descriptions.Item>
              <Descriptions.Item label='Next Billing Date' span={2}>
                {membership?.chargify?.nextBillingDate &&
                  moment(membership.chargify.nextBillingDate).format(
                    'DD/MM/YYYY'
                  )}
              </Descriptions.Item>
              <Descriptions.Item label='Link to Chargify' span={2}>
                {membership.chargify.site === undefined &&
                  'Missing Chargify Site'}
                {membership.chargify.subscriptionId === undefined &&
                  'Missing Chargify Subscription ID'}
                {!chargifySubscriptionUrl.includes('undefined') && (
                  <a
                    href={chargifySubscriptionUrl}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {chargifySubscriptionUrl}
                  </a>
                )}
              </Descriptions.Item>
            </Descriptions>
          </Skeleton>
        </ResponsiveCard>
      )}
    </>
  )
}

export default ChargifyInformation
