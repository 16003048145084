import React from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Descriptions } from 'antd'
import { Col, Row } from 'react-bootstrap'
import { Button, CircularProgress } from '@material-ui/core/'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import TextInput from '../../shared/form/TextInput'
import ResponsiveDrawer from '../../shared/ResponsiveDrawer'
import { transferMemberships } from '../../redux/actions/Members.actions'

const validationSchema = Yup.object({
  email: Yup.string()
    .email('Please enter a valid email format')
    .required('Email is required')
})

const EmailFormDrawer = ({ emailFormVisible, setEmailFormVisible, member }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const onClose = () => {
    setEmailFormVisible(false)
  }

  const initialValues = {
    email: ''
  }

  const handleFormSubmit = (values, { setSubmitting }) => {
    dispatch(
      transferMemberships(
        member._id,
        values.email,
        setSubmitting,
        setEmailFormVisible
      )
    )
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleFormSubmit
  })

  return (
    <ResponsiveDrawer
      title='Transfer Memberships to'
      onClose={onClose}
      visible={emailFormVisible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Descriptions
          bordered
          column={1}
          className={classes.descriptionsWrapper}
        >
          <Descriptions.Item label='Email'>
            <TextInput classes={classes} id='email' formik={formik} />
          </Descriptions.Item>
        </Descriptions>
        <Row className='mt-3 justify-content-end'>
          <Col xs={12} md={3}>
            <Button
              className='w-100'
              variant='contained'
              color='primary'
              type='submit'
              disabled={formik.isSubmitting}
            >
              {formik.isSubmitting && <CircularProgress size={20} />}
              Save
            </Button>
          </Col>
          <Col xs={12} md={3}>
            <Button
              className='w-100'
              variant='outlined'
              type='button'
              disabled={formik.isSubmitting}
              onClick={onClose}
            >
              {formik.isSubmitting && <CircularProgress size={20} />}
              Cancel
            </Button>
          </Col>
        </Row>
      </form>
    </ResponsiveDrawer>
  )
}

const useStyles = makeStyles((theme) => ({
  descriptionsWrapper: {
    '& .ant-descriptions-item-content': {
      padding: 0,

      '& input': {
        padding: '3px 7px 7px'
      }
    }
  }
}))

EmailFormDrawer.propTypes = {
  member: PropTypes.object.isRequired,
  emailFormVisible: PropTypes.bool,
  setEmailFormVisible: PropTypes.func
}

export default EmailFormDrawer
