import React from 'react'
import PropTypes from 'prop-types'
import { Divider } from 'antd'
import { Link } from 'react-router-dom'
import UseBreakpointConditions from '../../_base/hooks/UseBreakpointConditions'

const MembershipLink = ({ id, member }) => {
  const { belowLg } = UseBreakpointConditions()
  return (
    <>
      {!belowLg && <Divider type='vertical' />}
      <Link
        to={`/members/${member}/memberships/${id}`}
        style={{ textDecoration: 'underline' }}
        disabled={!member}
      >
        View
      </Link>
      <Divider type='vertical' />
    </>
  )
}

MembershipLink.propTypes = {
  id: PropTypes.string.isRequired,
  member: PropTypes.string.isRequired
}

export default MembershipLink
