import { createMuiTheme } from '@material-ui/core'
import {
  purple,
  green,
  deepOrange,
  blueGrey,
  deepPurple,
  lightBlue,
  grey,
  pink
} from '@material-ui/core/colors'

const breakpoints = {
  values: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1900
  }
}

const theme = createMuiTheme({
  breakpoints,
  getCurrentBreakpoint: (screenWidth) => {
    if (screenWidth <= breakpoints.values.sm) return 'xs'
    if (screenWidth <= breakpoints.values.md) return 'sm'
    if (screenWidth <= breakpoints.values.lg) return 'md'
    if (screenWidth <= breakpoints.values.xl) return 'lg'
    if (screenWidth <= breakpoints.values.xxl) return 'xl'
    return 'xxl'
  },
  paddings: {
    xs: '4px',
    sm: '8px',
    md: '12px',
    lg: '16px',
    xl: '24px',
    xxl: '30px'
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          padding: '0!important'
        }
      }
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#7C4DFF'
      }
    }
  },
  palette: {
    primary: {
      light: '#61acff',
      main: '#007DE0',
      dark: '#0052ad',
      contrastText: '#fff'
    },
    secondary: {
      light: '#b47cff',
      main: '#7C4DFF',
      dark: '#3f1dcb',
      contrastText: '#fff'
    },
    error: {
      light: '#EF9A9A',
      main: '#EF5353',
      dark: '#F44336',
      contrastText: '#FFF',
      transparent: 'rgba(234, 93, 93, 0.2)'
    },
    greys: {
      main: grey[500],
      light: grey[200]
    },
    main: {
      containerBackground: '#e9e9e9',
      cardShadow: '1px 1px 5px rgba(0,0,0,0.2)',
      boxShadow: '1px 1px 5px rgba(0,0,0,0.4)',
      border: '1px solid rgba(0,0,0,.125)',
      borderRadius: '0.25rem',
      dashboard: {
        cardOne: '#003b70',
        cardTwo: '#175b98',
        cardThree: '#00a4ed',
        roundSignups: '#0066b3',
        MonthlyLifers: purple[900],
        TotalSignups: '#880e4f',
        active: green[500],
        cancelled: deepOrange[500],
        suspended: blueGrey[500],
        'single - recurring': lightBlue[900],
        'single - upfront': lightBlue[500],
        'lifers - recurring': deepPurple[900],
        'lifers - upfront': deepPurple[500],
        'lifestyle 6 months upfront': deepPurple[300],
        'apple single upfront': pink[900]
      },
      table: {
        header: {
          color: grey[500]
        },
        body: {
          stripedLines: grey[100]
        }
      }
    }
  },
  CSS: {
    center: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }
})

export default theme
