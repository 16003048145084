/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useState, useMemo, useEffect } from 'react'
import { useHistory } from 'react-router'
import { Table } from 'antd'
import Highlighter from 'react-highlight-words'
import { SearchOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { getPendings } from '../../redux/actions/Memberships.actions.js'
import { makeStyles, useTheme } from '@material-ui/core'
import TableSearch from '../members/TableSearch.js'
import UseWindowDimensions from '../../_base/hooks/UseWindowDimensions.js'
// import MembershipsTag from '../members/MembershipsTag.js'
import MembershipLink from './MembershipLink.js'
import camelToTitleCase from '../../_base/helpers/text.helper.js'
import UseBreakpointConditions from '../../_base/hooks/UseBreakpointConditions.js'
import useQuery from '../../_base/hooks/UseQuery.js'
import moment from 'moment'

const MembershipsTable = () => {
  const classes = useStyles()
  const theme = useTheme()
  const { belowLg } = UseBreakpointConditions()
  const { width } = UseWindowDimensions()
  const {
    email,
    firstName,
    lastName,
    status,
    createdAt,
    page,
    limit
  } = useQuery([
    'email',
    'firstName',
    'lastName',
    'status',
    'createdAt',
    'page',
    'limit'
  ])

  const [expandedKey, setExpandedKey] = useState(null)
  const dispatch = useDispatch()
  const history = useHistory()
  const searchInput = React.createRef()
  const [inputsTouched, setInputsTouched] = useState(new Map())
  const [state, setState] = useState({
    searchText: '',
    searchedColumn: ''
  })

  const {
    loading,
    memberships: { pagination: remotePagination, data: memberships }
  } = useSelector((state) => state.memberships)

  useEffect(() => {
    let pagination = {}
    if (page || limit) {
      pagination = {
        pageSize: Number(limit) || 25,
        current: Number(page) || 1
      }
    }
    const filter = {
      email,
      firstName,
      lastName,
      status,
      createdAt
    }
    dispatch(getPendings({ pagination, filter }))
  }, [dispatch])

  const allKeys = useMemo(() => {
    if (belowLg && memberships) {
      const all = []
      const keys = Object.values(memberships)
      keys.forEach((key) => {
        all.push(key?._id)
      })
      return all
    }
  }, [belowLg, memberships])

  const getColumnSearchProps = (dataIndex, initialValue) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => {
      if (selectedKeys.length === 0 && initialValue) {
        // Store queryParams from url for updating filter:
        if (!inputsTouched.has(dataIndex)) {
          const inputsTouchedCopy = inputsTouched.set(dataIndex, initialValue)
          setInputsTouched(inputsTouchedCopy)
        }

        // Update this search input's saved text for easier editing:
        if (inputsTouched.get(dataIndex)) setSelectedKeys([initialValue])
      }
      return (
        <TableSearch
          dataIndex={dataIndex}
          handleSearch={handleSearch}
          handleReset={handleReset}
          setSearchState={setState}
          searchInput={searchInput}
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          clearFilters={clearFilters}
          confirm={confirm}
        />
      )
    },
    filterIcon: (filtered) => {
      return (
        <SearchOutlined
          style={{ color: filtered || initialValue ? '#1890ff' : undefined }}
        />
      )
    },
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.current.select()
          setInputsTouched(inputsTouched.set(dataIndex, null))
        }, 100)
      }
    },
    render: (text) =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      )
  })

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    selectedKeys[0] = selectedKeys[0].trim()
    confirm()
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    })
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setState({ searchText: '' })
  }

  const handleTableChange = (pagination, filter, sorter) => {
    // Update filter from url values:
    for (const [key, value] of inputsTouched.entries()) {
      if (value) filter[key] = value
    }
    if (pagination) {
      let url = `?limit=${pagination.pageSize}&page=${pagination.current}`

      if (filter) {
        for (const k in filter) {
          if (filter[k]) {
            url += `&${k}=${encodeURIComponent(filter[k])}`
          }
        }
      }
      if (sorter) {
        let s = sorter.order === 'descend' ? '-' : ''
        s += sorter.columnKey
        url += `&sort=${s}`
      }
      history.push(url)
    }
    dispatch(getPendings({ pagination, filter, sorter }))
  }

  const emailCol = {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    ...getColumnSearchProps('email', email),
    sorter: (a, b) => a - b,
    sortDirections: ['descend', 'ascend']
  }

  const firstNameCol = {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName',
    ...getColumnSearchProps('firstName', firstName),
    sorter: (a, b) => a - b,
    sortDirections: ['descend', 'ascend']
  }

  const lastNameCol = {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName',
    ...getColumnSearchProps('lastName', lastName),
    sorter: (a, b) => a - b,
    sortDirections: ['descend', 'ascend']
  }

  const statusCol = {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    // ...getColumnSearchProps('requestedDeletionAt', requestedDeletionAt),
    sorter: (a, b) => a - b,
    sortDirections: ['descend', 'ascend']
  }
  const createdAtCol = {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (createdAt) => (
      <span>{moment(createdAt).local().format('DD-MM-YYYY hh:mm:ss a')}</span>
    )
  }

  const viewCol = {
    title: '',
    dataIndex: '_id',
    key: 'actions',
    render: (_id, record) => <MembershipLink id={_id} member={record.member} />
  }

  // All columns are displayed for member in table:
  const xlColumns = [
    emailCol,
    firstNameCol,
    lastNameCol,
    statusCol,
    createdAtCol,
    viewCol
  ]

  // Last array is displayed for member in table [email]:
  const xsColumns = [
    {
      ...firstNameCol,
      children: [{ ...lastNameCol, children: [{ ...emailCol, children: [] }] }]
    }
  ]

  const tableHeaderObj = {
    xs: xsColumns,
    sm: xsColumns,
    md: xsColumns,
    lg: xlColumns,
    xl: xlColumns,
    xxl: xlColumns
  }

  const currentColumns = tableHeaderObj[theme.getCurrentBreakpoint(width)]

  const onExpandedRowChangeHandler = (expanded, { _id }) => {
    expandedKey === _id ? setExpandedKey(null) : setExpandedKey(_id)
  }

  // Loop through column object and if 'children' param exists, it is a header that has been moved to a full row:
  const getRemovedHeaders = () => {
    const arr = []
    let keepTraversing = true
    let activeNode = currentColumns[0]
    while (keepTraversing) {
      if (activeNode?.children) {
        arr.push(activeNode.key)
        activeNode = activeNode.children[0]
      } else {
        keepTraversing = false
      }
    }
    return arr
  }

  if (belowLg)
    return (
      <Table
        className={classes.table}
        rowKey={(record) => record._id}
        columns={currentColumns}
        dataSource={memberships}
        pagination={remotePagination}
        loading={loading}
        onChange={handleTableChange}
        expandable={{
          expandedRowRender: (record) => {
            const headers = getRemovedHeaders()
            return (
              <div>
                {headers.map((rowKey, i) => {
                  return (
                    <div key={i} className={classes.expandedRow}>
                      <span className={classes.expandedRowKey}>
                        {camelToTitleCase(rowKey)}:&nbsp;&nbsp;&nbsp;
                      </span>
                      <span>{record[rowKey]}</span>
                    </div>
                  )
                })}
                {belowLg && <MembershipLink id={record?._id} />}
              </div>
            )
          },
          onExpand: onExpandedRowChangeHandler,
          expandedRowKeys: belowLg ? allKeys : [expandedKey]
        }}
      />
    )

  return (
    <Table
      className={classes.table}
      rowKey={(record) => record._id}
      columns={xlColumns}
      dataSource={memberships}
      pagination={remotePagination}
      loading={loading}
      onChange={handleTableChange}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%!important',
    overflowX: 'scroll',
    backgroundColor: 'white',
    '& td': {
      padding: 0
    }
  },
  expandedRow: {
    display: 'flex',
    alignItems: 'center',
    '& > :nth-child(1)': {
      marginRight: '1rem'
    }
  },
  expandedRowKey: {
    textTransform: 'capitalize'
  },
  [theme.breakpoints.down('sm')]: {
    expandedRow: {
      justifyContent: 'space-between',
      '& > :nth-child(1)': {
        marginRight: 0
      }
    }
  },
  [theme.breakpoints.down('lg')]: {
    table: {
      colgroup: {
        display: 'none!important'
      },
      '& tr': {
        '& td:first-child:nth-last-child(2)': {
          display: 'none'
        },
        '& th:first-child:nth-last-child(2)': {
          display: 'none'
        }
      }
    }
  }
}))

export default MembershipsTable
