import React, { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CSVLink } from 'react-csv'
import { getCancellationReport } from '../../redux/actions/Report.actions'
import { parse } from 'json2csv'
import moment from 'moment'
import ScheduledCancellationReportButton from './ScheduledCancellationReportButton'

const DATE_FORMAT = 'DD-MM-YYYY'

const REPORTS = [
  {
    endpoint: '/cancellations',
    title: 'Cancellations Report for last 2 weeks',
    startReportPeriod: moment().subtract('2', 'weeks').format(DATE_FORMAT),
    endReportPeriod: moment().format(DATE_FORMAT)
  },
  {
    endpoint: '/scheduledToCancel6MoSignupOffer',
    title: '6 Months Upfront Signup Lifestyle members scheduled to cancel',
    startReportPeriod: moment().subtract('3', 'weeks').format(DATE_FORMAT),
    endReportPeriod: moment().add('3', 'weeks').format(DATE_FORMAT)
  },
  {
    endpoint: '/scheduledToCancel52Wk',
    title: '52 Week Lifestyle Members scheduled to cancel',
    startReportPeriod: moment().subtract('3', 'weeks').format(DATE_FORMAT),
    endReportPeriod: moment().add('3', 'weeks').format(DATE_FORMAT)
  },
  {
    endpoint: '/scheduledToCancel6MoPromoOffer',
    title: '6 Month Lifestyle Cancellation offers scheduled to cancel',
    startReportPeriod: moment().subtract('3', 'weeks').format(DATE_FORMAT),
    endReportPeriod: moment().add('3', 'weeks').format(DATE_FORMAT)
  }
]

const ScheduledCancellationReports = () => {
  const dispatch = useDispatch()
  const csvLinkRef = useRef(null)
  const { loading, reports } = useSelector((state) => state.reports)
  const todaysDateForFileName = moment().format('LLL')
  const [reportFetched, setReportFetched] = useState(null)

  const onSuccess = () => {
    csvLinkRef.current.link.click()
  }

  const fetchCancellationReportData = (endpoint) => {
    console.log('endpoint: ', endpoint)
    dispatch(getCancellationReport(onSuccess, endpoint))
  }

  const dataToDownload = reports.length > 0 ? parse(reports) : []
  return (
    <>
      {REPORTS.map((report, i) => (
        <ScheduledCancellationReportButton
          key={i}
          report={report}
          loading={loading}
          reportFetched={reportFetched}
          setReportFetched={setReportFetched}
          fetchData={fetchCancellationReportData}
        />
      ))}
      <CSVLink
        ref={csvLinkRef}
        headers={[
          `Data from ${reportFetched?.startReportPeriod} - ${reportFetched?.endReportPeriod}`
        ]}
        data={dataToDownload}
        filename={`Report ${reportFetched?.title} ${todaysDateForFileName}.csv`}
        target='_blank'
      />
    </>
  )
}

export default ScheduledCancellationReports
