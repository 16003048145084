import axios from '../AxiosWrapper'
import { setAlert } from './Alert.actions'
import {
  CANCEL_MEMBERSHIP_REQUEST,
  CANCEL_MEMBERSHIP_SUCCESS,
  CANCEL_MEMBERSHIP_FAIL,
  GET_MEMBERSHIP_BY_ID_REQUEST,
  GET_MEMBERSHIP_BY_ID_SUCCESS,
  GET_MEMBERSHIP_BY_ID_FAIL,
  UPDATE_MEMBERSHIP_PAYMENT_OPTIONS_REQUEST,
  UPDATE_MEMBERSHIP_PAYMENT_OPTIONS_SUCCESS,
  UPDATE_MEMBERSHIP_PAYMENT_OPTIONS_FAIL,
  ADD_SUBSCRIPTION_TO_MEMBERSHIP_REQUEST,
  ADD_SUBSCRIPTION_TO_MEMBERSHIP_SUCCESS,
  ADD_SUBSCRIPTION_TO_MEMBERSHIP_FAIL,
  UPDATE_MEMBERSHIP_PAYMENT_PLAN_REQUEST,
  UPDATE_MEMBERSHIP_PAYMENT_PLAN_SUCCESS,
  UPDATE_MEMBERSHIP_PAYMENT_PLAN_FAIL,
  REVOKE_CANCELLATION_REQUEST,
  REVOKE_CANCELLATION_SUCCESS,
  REVOKE_CANCELLATION_FAIL,
  SYNC_MEMBERSHIP_WITH_CHARGIFY_REQUEST,
  SYNC_MEMBERSHIP_WITH_CHARGIFY_SUCCESS,
  SYNC_MEMBERSHIP_WITH_CHARGIFY_FAIL,
  REACTIVATE_MEMBERSHIP_FAIL,
  REACTIVATE_MEMBERSHIP_REQUEST,
  REACTIVATE_MEMBERSHIP_SUCCESS,
  GET_MEMBERSHIPS_REQUEST,
  GET_MEMBERSHIPS_SUCCESS,
  GET_MEMBERSHIPS_FAIL,
  DELETE_MEMBERSHIP_REQUEST,
  DELETE_MEMBERSHIP_SUCCESS,
  DELETE_MEMBERSHIP_FAIL
} from '../constants/memberships.constants'

const config = {
  headers: {
    'Content-Type': 'application/json'
  }
}

export const getMembershipByID = (memberId, membershipId) => async (
  dispatch
) => {
  try {
    dispatch({ type: GET_MEMBERSHIP_BY_ID_REQUEST })

    const res = await axios.get(
      `${process.env.REACT_APP_API_HOST}admin/v1/members/${memberId}/memberships/${membershipId}`
    )

    dispatch({
      type: GET_MEMBERSHIP_BY_ID_SUCCESS,
      payload: res.data
    })
  } catch (error) {
    dispatch({
      type: GET_MEMBERSHIP_BY_ID_FAIL
    })
  }
}

export const cancelMembership = (
  memberId,
  membershipId,
  message,
  onSuccess,
  onFail
) => async (dispatch) => {
  try {
    dispatch({ type: CANCEL_MEMBERSHIP_REQUEST })
    const res = await axios.put(
      `${process.env.REACT_APP_API_HOST}admin/v1/members/${memberId}/memberships/${membershipId}/cancel`,
      message
    )
    dispatch({ type: CANCEL_MEMBERSHIP_SUCCESS, payload: res.data })
    dispatch(setAlert('Successfully cancelled membership', 'success'))
    onSuccess()
  } catch (err) {
    dispatch({ type: CANCEL_MEMBERSHIP_FAIL })
    onFail()
  }
}

export const updatePaymentOptions = ({
  memberId,
  membershipId,
  values,
  setSubmitting,
  setPaymentMethodFormVisible
}) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_MEMBERSHIP_PAYMENT_OPTIONS_REQUEST })
    const body = JSON.stringify(values)

    const res = await axios.put(
      `${process.env.REACT_APP_API_HOST}admin/v1/members/${memberId}/memberships/${membershipId}/payment-options`,
      body,
      config
    )

    dispatch({
      type: UPDATE_MEMBERSHIP_PAYMENT_OPTIONS_SUCCESS,
      payload: res.data
    })
  } catch (error) {
    dispatch({
      type: UPDATE_MEMBERSHIP_PAYMENT_OPTIONS_FAIL
    })
  } finally {
    setSubmitting(false)
    setPaymentMethodFormVisible(false)
  }
}

export const addSubscriptionToMembership = ({
  memberId,
  membershipId,
  values,
  setSubmitting,
  setAddSubscriptionFormVisible
}) => async (dispatch) => {
  try {
    dispatch({ type: ADD_SUBSCRIPTION_TO_MEMBERSHIP_REQUEST })
    const body = JSON.stringify(values)

    const res = await axios.post(
      `${process.env.REACT_APP_API_HOST}admin/v1/members/${memberId}/memberships/${membershipId}/add-subscription`,
      body,
      config
    )

    dispatch({
      type: ADD_SUBSCRIPTION_TO_MEMBERSHIP_SUCCESS,
      payload: res.data
    })
  } catch (error) {
    dispatch({
      type: ADD_SUBSCRIPTION_TO_MEMBERSHIP_FAIL
    })
  } finally {
    setSubmitting(false)
    setAddSubscriptionFormVisible(false)
  }
}

export const updateMembershipPaymentPlan = ({
  memberId,
  membershipId,
  values,
  setSubmitting,
  setChangePaymentPlanFormVisible
}) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_MEMBERSHIP_PAYMENT_PLAN_REQUEST })
    const body = JSON.stringify(values)

    const res = await axios.put(
      `${process.env.REACT_APP_API_HOST}admin/v1/members/${memberId}/memberships/${membershipId}/payment-plan`,
      body,
      config
    )

    dispatch({
      type: UPDATE_MEMBERSHIP_PAYMENT_PLAN_SUCCESS,
      payload: res.data
    })
  } catch (error) {
    dispatch({
      type: UPDATE_MEMBERSHIP_PAYMENT_PLAN_FAIL
    })
  } finally {
    setSubmitting(false)
    setChangePaymentPlanFormVisible(false)
  }
}

export const syncMembershipWithChargify = ({
  memberId,
  membershipId
}) => async (dispatch) => {
  try {
    dispatch({ type: SYNC_MEMBERSHIP_WITH_CHARGIFY_REQUEST })

    const res = await axios.post(
      `${process.env.REACT_APP_API_HOST}admin/v1/members/${memberId}/memberships/${membershipId}/sync-chargify`,
      {},
      config
    )

    dispatch({
      type: SYNC_MEMBERSHIP_WITH_CHARGIFY_SUCCESS,
      payload: res.data
    })
  } catch (error) {
    dispatch({
      type: SYNC_MEMBERSHIP_WITH_CHARGIFY_FAIL
    })
  }
}

export const revokeCancellation = (
  memberId,
  membershipId,
  cancellationId
) => async (dispatch) => {
  try {
    dispatch({ type: REVOKE_CANCELLATION_REQUEST })
    const res = await axios.delete(
      `${process.env.REACT_APP_API_HOST}admin/v1/members/${memberId}/memberships/${membershipId}/cancellation/${cancellationId}`,
      config
    )
    dispatch({ type: REVOKE_CANCELLATION_SUCCESS, payload: res.data })
    dispatch(
      setAlert('Successfully revoked/reactivated cancellation', 'success')
    )
  } catch (err) {
    dispatch({ type: REVOKE_CANCELLATION_FAIL })
  }
}

export const reactivateMembership = (
  memberId,
  membershipId,
  cancellationId,
  getMemberById
) => async (dispatch) => {
  try {
    dispatch({ type: REACTIVATE_MEMBERSHIP_REQUEST })
    const res = await axios.put(
      `${process.env.REACT_APP_API_HOST}admin/v1/members/${memberId}/memberships/${membershipId}/cancellation/${cancellationId}/reactivate`
    )
    dispatch({ type: REACTIVATE_MEMBERSHIP_SUCCESS, payload: res.data })
    dispatch(setAlert('Successfully reactivated membership', 'success'))
    getMemberById()
  } catch (err) {
    dispatch({ type: REACTIVATE_MEMBERSHIP_FAIL })
  }
}

const urlFormater = (url, queryStr) => {
  const link = url.includes('?') ? `${url}&` : `${url}?`
  return `${link}${queryStr}`
}

export const getPendings = (props) => async (dispatch) => {
  const {
    pagination,
    filter,
    sorter = { order: 'descend', columnKey: 'createdAt' }
  } = props || {}

  let url = `${process.env.REACT_APP_API_HOST}admin/v1/memberships/pendings`

  if (pagination.pageSize && pagination.current)
    url = urlFormater(
      url,
      `limit=${pagination.pageSize}&page=${pagination.current}`
    )

  if (filter) {
    for (const k in filter) {
      if (filter[k]) {
        url = urlFormater(url, `${k}=${encodeURIComponent(filter[k])}`)
      }
    }
  }

  if (sorter?.order) {
    let s = sorter.order === 'descend' ? '-' : ''
    s += sorter.columnKey
    url = urlFormater(url, `sort=${s}`)
  }

  try {
    dispatch({ type: GET_MEMBERSHIPS_REQUEST })
    const res = await axios.get(encodeURI(url))

    dispatch({ type: GET_MEMBERSHIPS_SUCCESS, payload: res.data })
  } catch (error) {
    dispatch({ type: GET_MEMBERSHIPS_FAIL })
  }
}

export const deleteMembership = ({ memberId, membershipId }) => async (
  dispatch
) => {
  try {
    dispatch({ type: DELETE_MEMBERSHIP_REQUEST })

    const res = await axios.delete(
      `${process.env.REACT_APP_API_HOST}admin/v1/members/${memberId}/memberships/${membershipId}/`,
      {},
      config
    )

    dispatch({
      type: DELETE_MEMBERSHIP_SUCCESS,
      payload: res.data
    })
  } catch (error) {
    dispatch({
      type: DELETE_MEMBERSHIP_FAIL
    })
  }
}
