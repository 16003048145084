import {
  CANCEL_MEMBERSHIP_REQUEST,
  CANCEL_MEMBERSHIP_SUCCESS,
  CANCEL_MEMBERSHIP_FAIL,
  GET_MEMBERSHIP_BY_ID_REQUEST,
  GET_MEMBERSHIP_BY_ID_SUCCESS,
  GET_MEMBERSHIP_BY_ID_FAIL,
  UPDATE_MEMBERSHIP_PAYMENT_OPTIONS_REQUEST,
  UPDATE_MEMBERSHIP_PAYMENT_OPTIONS_SUCCESS,
  UPDATE_MEMBERSHIP_PAYMENT_OPTIONS_FAIL,
  ADD_SUBSCRIPTION_TO_MEMBERSHIP_REQUEST,
  ADD_SUBSCRIPTION_TO_MEMBERSHIP_SUCCESS,
  ADD_SUBSCRIPTION_TO_MEMBERSHIP_FAIL,
  UPDATE_MEMBERSHIP_PAYMENT_PLAN_REQUEST,
  UPDATE_MEMBERSHIP_PAYMENT_PLAN_SUCCESS,
  UPDATE_MEMBERSHIP_PAYMENT_PLAN_FAIL,
  REVOKE_CANCELLATION_REQUEST,
  REVOKE_CANCELLATION_SUCCESS,
  REVOKE_CANCELLATION_FAIL,
  SYNC_MEMBERSHIP_WITH_CHARGIFY_REQUEST,
  SYNC_MEMBERSHIP_WITH_CHARGIFY_SUCCESS,
  SYNC_MEMBERSHIP_WITH_CHARGIFY_FAIL,
  REACTIVATE_MEMBERSHIP_REQUEST,
  REACTIVATE_MEMBERSHIP_SUCCESS,
  REACTIVATE_MEMBERSHIP_FAIL,
  GET_MEMBERSHIPS_REQUEST,
  GET_MEMBERSHIPS_SUCCESS,
  GET_MEMBERSHIPS_FAIL
} from '../constants/memberships.constants'

export const membershipsReducer = (state = { memberships: {} }, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_MEMBERSHIPS_REQUEST:
      return { loading: true, memberships: {} }
    case GET_MEMBERSHIPS_SUCCESS:
      return {
        loading: false,
        memberships: payload
      }
    case GET_MEMBERSHIPS_FAIL:
      return {
        loading: false,
        memberships: {}
      }
    default:
      return state
  }
}

export const membershipReducer = (state = {}, action) => {
  const { type, payload } = action

  switch (type) {
    case GET_MEMBERSHIP_BY_ID_REQUEST:
      return { loading: true }
    case REACTIVATE_MEMBERSHIP_REQUEST:
      return { ...state, loadingReactivateMembership: true }
    case UPDATE_MEMBERSHIP_PAYMENT_OPTIONS_REQUEST:
      return { ...state, loadingPaymentOptionsUpdate: true }
    case REVOKE_CANCELLATION_REQUEST:
      return { ...state, loadingCancellationDeletion: true }
    case ADD_SUBSCRIPTION_TO_MEMBERSHIP_REQUEST:
      return { ...state, loadingAddSubscription: true }
    case UPDATE_MEMBERSHIP_PAYMENT_PLAN_REQUEST:
      return { ...state, loadingUpdatePaymentPlan: true }
    case SYNC_MEMBERSHIP_WITH_CHARGIFY_REQUEST:
      return { ...state, loadingChargifySync: true }
    case CANCEL_MEMBERSHIP_REQUEST:
      return { ...state, loadingMembershipCancellation: true }
    case GET_MEMBERSHIP_BY_ID_SUCCESS:
    case UPDATE_MEMBERSHIP_PAYMENT_OPTIONS_SUCCESS:
    case ADD_SUBSCRIPTION_TO_MEMBERSHIP_SUCCESS:
    case UPDATE_MEMBERSHIP_PAYMENT_PLAN_SUCCESS:
    case REACTIVATE_MEMBERSHIP_SUCCESS:
    case CANCEL_MEMBERSHIP_SUCCESS:
    case SYNC_MEMBERSHIP_WITH_CHARGIFY_SUCCESS:
      return {
        loading: false,
        loadingPaymentOptionsUpdate: false,
        loadingReactivateMembership: false,
        loadingAddSubscription: false,
        loadingUpdatePaymentPlan: false,
        loadingChargifySync: false,
        loadingMembershipCancellation: false,
        data: payload
      }
    case REVOKE_CANCELLATION_SUCCESS:
      return {
        ...state,
        loadingCancellationDeletion: false,
        data: payload,
        cancelled: true
      }
    case GET_MEMBERSHIP_BY_ID_FAIL:
    case UPDATE_MEMBERSHIP_PAYMENT_OPTIONS_FAIL:
    case ADD_SUBSCRIPTION_TO_MEMBERSHIP_FAIL:
    case UPDATE_MEMBERSHIP_PAYMENT_PLAN_FAIL:
    case REVOKE_CANCELLATION_FAIL:
    case REACTIVATE_MEMBERSHIP_FAIL:
    case SYNC_MEMBERSHIP_WITH_CHARGIFY_FAIL:
    case CANCEL_MEMBERSHIP_FAIL:
      return {
        ...state,
        loadingCancellation: false,
        loadingCancellationDeletion: false,
        loadingPaymentOptionsUpdate: false,
        loadingAddSubscription: false,
        loadingUpdatePaymentPlan: false,
        loadingChargifySync: false,
        loadingReactivateMembership: false,
        loadingMembershipCancellation: false,
        loading: false,
        cancelled: false
      }
    default:
      return state
  }
}
