import React, { useEffect } from 'react'
import { CircularProgress, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import { logout } from '../redux/actions/User.actions'
import { setAlert } from '../redux/actions/Alert.actions'
import { useCookies } from 'react-cookie'

import Logo12WBT from '../_base/assets/img/12wbtlogo.png'

const Loader = () => {
  const removeCookie = useCookies(['token'])[2]
  const classes = useStyles()
  const dispatch = useDispatch()

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(
        setAlert(
          'Application timed out, check your connection and try again later'
        )
      )
      localStorage.clear()
      removeCookie('token')
      dispatch(logout())
    }, 25000)
    return () => clearTimeout(timer)
  }, [])

  return (
    <Grid
      container
      direction='column'
      justify='center'
      alignItems='center'
      style={{ height: '100vh' }}
    >
      <img src={Logo12WBT} alt='Logo' className={classes.logo} />
      <CircularProgress
        color='primary'
        variant='indeterminate'
        size={100}
      ></CircularProgress>
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  logo: {
    margin: theme.spacing(5),
    maxWidth: '150px'
  }
}))

export default Loader
