import React from 'react'
import { useSelector } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import { Skeleton } from 'antd'
import ResponsiveCard from '../../shared/ResponsiveCard'

const MembershipGeneralInformation = () => {
  const { loading, data: membership } = useSelector((state) => state.membership)

  return (
    <ResponsiveCard
      style={{
        overflowX: 'hidden'
      }}
    >
      <Skeleton loading={loading} active>
        {membership && (
          <Row style={{ textAlign: 'center' }}>
            <Col xs={12} sm='auto'>
              <p
                style={{
                  fontSize: 'small'
                }}
              >
                Issues Counter
              </p>
              <h4>{membership.issues?.length || 0}</h4>
            </Col>
            <Col xs={12} sm='auto'>
              <p
                style={{
                  fontSize: 'small'
                }}
              >
                Subscriptions Counter
              </p>
              <h4>{membership.subs?.length}</h4>
            </Col>
            <Col xs={12} sm='auto'>
              <p
                style={{
                  fontSize: 'small'
                }}
              >
                Status
              </p>
              <h4>{membership.status}</h4>
            </Col>
            <Col xs={12} sm='auto'>
              <p
                style={{
                  fontSize: 'small'
                }}
              >
                Payment Plan
              </p>
              <h4>{membership.paymentPlan?.name}</h4>
            </Col>
          </Row>
        )}
      </Skeleton>
    </ResponsiveCard>
  )
}

export default MembershipGeneralInformation
