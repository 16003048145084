import React from 'react'
import { CardColumns } from 'react-bootstrap'
import { makeStyles } from '@material-ui/core'

// import EBooksCard from './cards/EBooksCard'
// import CancellationsCard from './cards/CancellationsCard'
// import ActiveLifersByStatusCard from './cards/ActiveLifersByStatusCard'
// import LifersNutritionCard from './cards/LifersNutritionCard'
// import ActiveLifersByPaymentPlanCard from './cards/ActiveLifersByPaymentPlanCard'
// import LifersByMonthCard from './cards/LifersByMonthCard'
// import SubscriptionByRound from './cards/SubscriptionByRound'

const QuickCards = () => {
  const classes = useStyles()
  return (
    <CardColumns className={classes.cardContainer}>
      {
        // <ActiveLifersByPaymentPlanCard />
        /*
      <ActiveLifersByStatusCard />
      <LifersByMonthCard />
      <LifersNutritionCard />
      <SubscriptionByRound />
      <CancellationsCard />
      <EBooksCard /> */
      }
    </CardColumns>
  )
}

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    // columnCount: 1
    // margin: '2rem 0',
    // '& > div': {
    //   '&:first-child': {
    //     marginLeft: '0'
    //   },
    //   '&:last-child': {
    //     marginRight: '0'
    //   }
    // }
  }
}))

export default QuickCards
