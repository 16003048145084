import { combineReducers } from 'redux'
import alert from './Alert.reducers'
import { userReducer } from './User.reducers'
import {
  totalCancellationsReducer,
  totalEbooksReducer,
  totalLifersByMonthReducer,
  totalLifersByStatusReducer,
  totalLifersNutritionReducer,
  totalLifersReducer,
  totalMonthlyLifersReducer,
  totalRoundSignupsReducer,
  totalSignupsReducer,
  totalSubscriptionByRoundReducer
} from './Dashboard.reducers'
import { memberReducer, membersReducer } from './Members.reducers'
import { membershipReducer, membershipsReducer } from './Memberships.reducers'
import { membershipTypesReducer } from './MembershipTypes.reducers'
import { reportsReducer } from './Reports.reducers'

export default combineReducers({
  alert,
  members: membersReducer,
  member: memberReducer,
  membership: membershipReducer,
  memberships: membershipsReducer,
  membershipTypes: membershipTypesReducer,
  totalLifers: totalLifersReducer,
  totalLifersByStatus: totalLifersByStatusReducer,
  totalLifersByMonth: totalLifersByMonthReducer,
  totalLifersNutrition: totalLifersNutritionReducer,
  totalCancellations: totalCancellationsReducer,
  totalSubscriptionsByRound: totalSubscriptionByRoundReducer,
  totalEbooks: totalEbooksReducer,
  totalMonthlyLifers: totalMonthlyLifersReducer,
  totalRoundSignups: totalRoundSignupsReducer,
  totalSignups: totalSignupsReducer,
  user: userReducer,
  reports: reportsReducer
})
