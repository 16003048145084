import React from 'react'
import PropTypes from 'prop-types'
import { FormHelperText, makeStyles, TextareaAutosize } from '@material-ui/core'

const TextArea = ({ formik, id, disabled }) => {
  const error = formik.touched[id] && Boolean(formik.errors[id])
  const classes = useStyles({ error })
  return (
    <>
      <TextareaAutosize
        value={formik.values[id] || ''}
        id={id}
        name={id}
        rowsMax={12}
        rowsMin={2}
        onChange={formik.handleChange}
        disabled={disabled}
        className={classes.textArea}
      />
      {error && (
        <FormHelperText className={classes.errorText}>
          {formik.errors[id]}
        </FormHelperText>
      )}
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  textArea: {
    width: '100%',
    border: ({ error }) => (error ? '1px solid red' : '')
  },
  errorText: {
    color: theme.palette.error.main
  }
}))

TextArea.propTypes = {
  id: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
  classes: PropTypes.object,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
}

export default TextArea
