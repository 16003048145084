import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './redux/store'
import App from './App'
import Loader from './shared/Loader'

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<Loader />} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)

// Actions must be dispatched from the same store instance:
const { dispatch: directStoreDispatch } = store
export { directStoreDispatch }

// expose store when run in Cypress
if (window.Cypress) {
  window.store = store
}
