import React from 'react'
import { Col } from 'react-bootstrap'
import { makeStyles } from '@material-ui/core/styles'

import MishLoginBG from '../../_base/assets/img/MishLoginBG.jpg'

const LeftPanel = () => {
  const classes = useStyles()
  return <Col className={classes.image} />
}

const useStyles = makeStyles((theme) => ({
  image: {
    backgroundImage: `url(${MishLoginBG})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  [theme.breakpoints.down('sm')]: {
    image: {
      backgroundPositionY: '35%',
      minHeight: '20%'
    }
  }
}))

export default LeftPanel
