import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Layout, Menu, Avatar } from 'antd'
import { makeStyles } from '@material-ui/core'

import { logout } from '../redux/actions/User.actions'

import Logo from '../_base/assets/img/12wbtlogowhite.png'

const { Header } = Layout
const { SubMenu, Item } = Menu

function HeaderTemplate() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)

  const userName = () => {
    if (user.loading || !user || !user.member) {
      return '...'
    }

    return `${user.member.member.first_name} ${user.member.member.last_name}`
  }

  const userInitials = () => {
    if (user.loading || !user || !user.member) {
      return '...'
    }

    return `${user.member.member.first_name}${user.member.member.last_name}`
  }

  const logoutClickHandler = () => {
    dispatch(logout())
  }

  return (
    <Header className='header d-flex justify-content-between'>
      <div>
        <img src={Logo} alt='Logo' className={classes.logo} />
      </div>
      <Menu theme='dark' mode='horizontal'>
        <SubMenu
          icon={<Avatar className='bg-info'>{userInitials()}</Avatar>}
          title={userName()}
          key='user'
        >
          <Item onClick={logoutClickHandler}>Logout</Item>
        </SubMenu>
      </Menu>
    </Header>
  )
}

const useStyles = makeStyles((theme) => ({
  logo: {
    maxWidth: '120px'
  }
}))

export default HeaderTemplate
