import React from 'react'
import PropTypes from 'prop-types'
import { Row } from 'react-bootstrap'

function PageContainer({ children }) {
  return (
    <Row className='m-0' style={{ height: '100vh' }}>
      {children}
    </Row>
  )
}

PageContainer.propTypes = {
  children: PropTypes.object
}

export default PageContainer
