import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Container, Row } from 'react-bootstrap'
import * as Components from '../components/members'
import { getMemberById } from '../redux/actions/Members.actions'

function MemberDetailsPage() {
  const { memberId } = useParams()
  const dispatch = useDispatch()

  const { loading, data: member } = useSelector((state) => state.member)

  useEffect(() => {
    if (!loading && (!member || member._id !== memberId)) {
      dispatch(getMemberById(memberId))
    }
  }, [dispatch, member])

  return (
    <Container fluid>
      <Row>
        <Col xs={12} md={3}>
          <Components.MemberActionCard members />
        </Col>
        <Col xs={12} md={9}>
          <Components.MemberGeneralInformation />
          <Components.MemberInformation />
          <Components.MemberDataTabs />
        </Col>
      </Row>
    </Container>
  )
}

export default MemberDetailsPage
