import { useMediaQuery, useTheme } from '@material-ui/core'

const UseBreakpointConditions = () => {
  const theme = useTheme()
  const belowSm = useMediaQuery(
    `(max-width: ${theme.breakpoints.values.sm - 1}px)`
  )
  const belowMd = useMediaQuery(
    `(max-width: ${theme.breakpoints.values.md - 1}px)`
  )
  const belowLg = useMediaQuery(
    `(max-width: ${theme.breakpoints.values.lg - 1}px)`
  )
  const belowXl = useMediaQuery(
    `(max-width: ${theme.breakpoints.values.xl - 1}px)`
  )
  const belowXxl = useMediaQuery(
    `(max-width: ${theme.breakpoints.values.xxl - 1}px)`
  )

  return { belowSm, belowMd, belowLg, belowXl, belowXxl }
}

export default UseBreakpointConditions
