import axios from 'axios'
import { directStoreDispatch } from '../index'
import { logout } from './actions/User.actions'
import { setAlert } from './actions/Alert.actions'

const axiosWrapper = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  responseType: 'json'
})

axiosWrapper.defaults.withCredentials = true

axiosWrapper.interceptors.response.use(undefined, function (error) {
  if (error.response.status === 401) {
    directStoreDispatch(
      setAlert(error.response.data?.message || 'Invalid authentication')
    )
    directStoreDispatch(logout())
  }

  if (error.response.status !== 401) {
    if (error.response.data?.message) {
      directStoreDispatch(setAlert(error.response.data.message))
    } else {
      directStoreDispatch(
        setAlert(`${error.response?.statusText || 'Something went wrong!'}`)
      )
    }
  }

  return Promise.reject(error)
})

export default axiosWrapper
