export const TOTAL_LIFERS_REQUEST = 'TOTAL_LIFERS_REQUEST'
export const TOTAL_LIFERS_SUCCESS = 'TOTAL_LIFERS_SUCCESS'
export const TOTAL_LIFERS_FAIL = 'TOTAL_LIFERS_FAIL'

export const TOTAL_LIFERS_BY_STATUS_REQUEST = 'TOTAL_LIFERS_BY_STATUS_REQUEST'
export const TOTAL_LIFERS_BY_STATUS_SUCCESS = 'TOTAL_LIFERS_BY_STATUS_SUCCESS'
export const TOTAL_LIFERS_BY_STATUS_FAIL = 'TOTAL_LIFERS_BY_STATUS_FAIL'

export const TOTAL_LIFERS_BY_MONTH_REQUEST = 'TOTAL_LIFERS_BY_MONTH_REQUEST'
export const TOTAL_LIFERS_BY_MONTH_SUCCESS = 'TOTAL_LIFERS_BY_MONTH_SUCCESS'
export const TOTAL_LIFERS_BY_MONTH_FAIL = 'TOTAL_LIFERS_BY_MONTH_FAIL'

export const TOTAL_LIFERS_NUTRITION_REQUEST = 'TOTAL_LIFERS_NUTRITION_REQUEST'
export const TOTAL_LIFERS_NUTRITION_SUCCESS = 'TOTAL_LIFERS_NUTRITION_SUCCESS'
export const TOTAL_LIFERS_NUTRITION_FAIL = 'TOTAL_LIFERS_NUTRITION_FAIL'

export const TOTAL_CANCELLATIONS_REQUEST = 'TOTAL_CANCELLATIONS_REQUEST'
export const TOTAL_CANCELLATIONS_SUCCESS = 'TOTAL_CANCELLATIONS_SUCCESS'
export const TOTAL_CANCELLATIONS_FAIL = 'TOTAL_CANCELLATIONS_FAIL'

export const TOTAL_EBOOKS_REQUEST = 'TOTAL_EBOOKS_REQUEST'
export const TOTAL_EBOOKS_SUCCESS = 'TOTAL_EBOOKS_SUCCESS'
export const TOTAL_EBOOKS_FAIL = 'TOTAL_EBOOKS_FAIL'

export const TOTAL_MONTHLY_LIFERS_REQUEST = 'TOTAL_MONTHLY_LIFERS_REQUEST'
export const TOTAL_MONTHLY_LIFERS_SUCCESS = 'TOTAL_MONTHLY_LIFERS_SUCCESS'
export const TOTAL_MONTHLY_LIFERS_FAIL = 'TOTAL_MONTHLY_LIFERS_FAIL'

export const MONTHLY_LIFERS_REQUEST = 'MONTHLY_LIFERS_REQUEST'
export const MONTHLY_LIFERS_SUCCESS = 'MONTHLY_LIFERS_SUCCESS'
export const MONTHLY_LIFERS_FAIL = 'MONTHLY_LIFERS_FAIL'

export const TOTAL_ROUND_SIGNUPS_REQUEST = 'TOTAL_ROUND_SIGNUPS_REQUEST'
export const TOTAL_ROUND_SIGNUPS_SUCCESS = 'TOTAL_ROUND_SIGNUPS_SUCCESS'
export const TOTAL_ROUND_SIGNUPS_FAIL = 'TOTAL_ROUND_SIGNUPS_FAIL'

export const TOTAL_SUBSCRIPTIONS_BY_ROUND_REQUEST =
  'TOTAL_SUBSCRIPTIONS_BY_ROUND_REQUEST'
export const TOTAL_SUBSCRIPTIONS_BY_ROUND_SUCCESS =
  'TOTAL_SUBSCRIPTIONS_BY_ROUND_SUCCESS'
export const TOTAL_SUBSCRIPTIONS_BY_ROUND_FAIL =
  'TOTAL_SUBSCRIPTIONS_BY_ROUND_FAIL'

export const TOTAL_SIGNUPS_REQUEST = 'TOTAL_SIGNUPS_REQUEST'
export const TOTAL_SIGNUPS_SUCCESS = 'TOTAL_SIGNUPS_SUCCESS'
export const TOTAL_SIGNUPS_FAIL = 'TOTAL_SIGNUPS_FAIL'
