import React, { Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Alert } from 'react-bootstrap'
import { dismissAlert } from '../redux/actions/Alert.actions'

const AlertItem = (alert) => {
  const { type, id, msg } = alert
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(dismissAlert(id))
  }

  return (
    <Alert variant={type} onClose={handleClose} dismissible key={id}>
      {msg}
    </Alert>
  )
}

const AlertComponent = () => {
  const alerts = useSelector((state) => state.alert)
  const arrayUniqueByKey = [
    ...new Map(alerts.map((item) => [item.msg, item])).values()
  ]

  return (
    <Fragment>
      {arrayUniqueByKey !== null &&
        arrayUniqueByKey.length > 0 &&
        arrayUniqueByKey.map((alert) => AlertItem(alert))}
    </Fragment>
  )
}

export default AlertComponent
