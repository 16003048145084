import React from 'react'
import PropTypes from 'prop-types'
import { Divider } from 'antd'
import { Link } from 'react-router-dom'
import UseBreakpointConditions from '../../_base/hooks/UseBreakpointConditions'

const MembershipLink = ({ id }) => {
  const { belowLg } = UseBreakpointConditions()
  return (
    <>
      {!belowLg && <Divider type='vertical' />}
      <Link to={`/members/${id}`} style={{ textDecoration: 'underline' }}>
        View
      </Link>
      <Divider type='vertical' />
    </>
  )
}

MembershipLink.propTypes = {
  id: PropTypes.string.isRequired
}

export default MembershipLink
