import React from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Descriptions } from 'antd'
import { Alert, Col, Row } from 'react-bootstrap'
import TextArea from '../../shared/form/TextArea'
import { Button, CircularProgress } from '@material-ui/core/'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import ResponsiveDrawer from '../../shared/ResponsiveDrawer'
import { cancelMembership } from '../../redux/actions/Memberships.actions'
import { Redirect } from 'react-router-dom'
import { getMemberById } from '../../redux/actions/Members.actions'

const validationSchema = Yup.object({
  message: Yup.string().required('Please provide a reason for cancellation')
})

const CancelMembershipDrawer = ({
  drawerVisible,
  setDrawerVisible,
  memberId,
  membership
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const onClose = () => setDrawerVisible(false)

  const formik = useFormik({
    initialValues: { message: '' },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        cancelMembership(memberId, membership._id, values, onSuccess, onFail)
      )
    }
  })

  const onSuccess = () => {
    dispatch(getMemberById(memberId))
    formik.setSubmitting(false)
    onClose()
    return <Redirect to={`members/${memberId}`} />
  }

  const onFail = () => {
    formik.setSubmitting(false)
    onClose()
  }

  return (
    <ResponsiveDrawer
      title='Cancel Membership'
      onClose={onClose}
      visible={drawerVisible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Alert variant='info'>
        Cancelling a membership will cancel the Chargify subscription
        immediately.
      </Alert>
      <form onSubmit={formik.handleSubmit}>
        <Descriptions
          bordered
          className={classes.descriptionsWrapper}
          column={1}
        >
          <Descriptions.Item label='Reason for cancellation'>
            <TextArea id='message' formik={formik} />
          </Descriptions.Item>
        </Descriptions>
        <Row className='mt-3 justify-content-end'>
          <Col xs={12} md={3}>
            <Button
              className='w-100'
              variant='contained'
              color='primary'
              type='submit'
              disabled={formik.isSubmitting}
            >
              {formik.isSubmitting && <CircularProgress size={20} />}
              Save
            </Button>
          </Col>
          <Col xs={12} md={3}>
            <Button
              className='w-100'
              variant='outlined'
              type='button'
              disabled={formik.isSubmitting}
              onClick={onClose}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </form>
    </ResponsiveDrawer>
  )
}

const useStyles = makeStyles((theme) => ({
  descriptionsWrapper: {
    '& .ant-descriptions-item-label': {
      width: '33%'
    },

    '& .ant-descriptions-item-content': {
      padding: 0,

      '& input': {
        padding: '3px 7px 7px'
      }
    }
  },
  input: {
    '& .MuiInput-underline:before': {
      borderBottomColor: 'transparent'
    },
    '& .MuiInput-underline:hover': {
      borderBottomColor: 'rgba(0, 0, 0, 0.3)'
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: 'rgba(0, 0, 0, 0.3)'
    }
  }
}))

CancelMembershipDrawer.propTypes = {
  drawerVisible: PropTypes.bool,
  setDrawerVisible: PropTypes.func,
  memberId: PropTypes.string.isRequired,
  membership: PropTypes.object.isRequired
}

export default CancelMembershipDrawer
