import React from 'react'
import { useSelector } from 'react-redux'
import { ThemeProvider } from '@material-ui/core/styles'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import * as Pages from './pages/index'
import PageContainer from './shared/PageContainer'
import Layout from './layout'
// import { loadUser } from './redux/actions/User.actions'
import theme from './theme'
import 'antd/dist/antd.css'

import PrivateRoute from './routes/PrivateRoute'
import setAuthToken from './_base/helpers/AuthTokenHelper'

import Loader from './shared/Loader'

if (localStorage.token) {
  setAuthToken(localStorage.token)
}

const App = () => {
  // const dispatch = useDispatch()

  const { loading } = useSelector((state) => state.user)

  // useEffect(() => {
  //   if (!loading && !isAuthenticated && !member) {
  //     dispatch(loadUser())
  //   }
  // }, [dispatch])

  const DefaultContainer = () => (
    <Layout>
      <Switch>
        <Route exact path='/dashboard' component={Pages.DashboardPage} />

        <Route exact path='/members' component={Pages.MembersPage} />
        <Route exact path='/requests' component={Pages.DeletionRequestsPage} />
        <Route exact path='/pendings' component={Pages.PendingsPage} />
        <Route
          exact
          path='/members/:memberId'
          component={Pages.MemberDetailsPage}
        />
        <Route
          exact
          path='/members/:memberId/memberships/:membershipId'
          component={Pages.MembershipDetailsPage}
        />

        <Route
          exact
          path='/prospective-members'
          component={Pages.ProspectiveMembersPage}
        />

        <Route exact path='/reports' component={Pages.Reports} />

        <Route component={Pages.Error404Page} />
      </Switch>
    </Layout>
  )

  return (
    <ThemeProvider theme={theme}>
      <PageContainer>
        {loading ? (
          <Loader />
        ) : (
          <Router>
            <Switch>
              <Route exact path='/' component={Pages.LoginPage} />
              <Route exact path='/login' component={Pages.LoginPage} />
              <PrivateRoute component={DefaultContainer} />
              <Route component={Pages.Error404Page} />
            </Switch>
          </Router>
        )}
      </PageContainer>
    </ThemeProvider>
  )
}
export default App
