import React from 'react'
import { Col, Row } from 'react-bootstrap'

function ProspectiveMembersPage() {
  return (
    <Row className='bg-white'>
      <Col>Prospective Members</Col>
    </Row>
  )
}
export default ProspectiveMembersPage
