import React from 'react'
import PropTypes from 'prop-types'
import { Tag } from 'antd'

const StatusTag = ({ status }) => {
  switch (status) {
    case 'active':
      return <Tag color='green'>{status}</Tag>
    case 'cancelled':
    case 'cancel pending':
    case 'pending':
      return <Tag color='orange'>{status}</Tag>
    case 'suspended':
      return <Tag color='magenta'>{status}</Tag>
    case 'paused':
      return <Tag color='geekblue'>{status}</Tag>
    case 'failed':
      return <Tag color='error'>{status}</Tag>
    case 'banned':
      return <Tag color='black'>{status}</Tag>
    default:
      return <Tag color='red'>{status}</Tag>
  }
}

StatusTag.propTypes = {
  status: PropTypes.string
}

export default StatusTag
