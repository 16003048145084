import React from 'react'
import { useSelector } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded'
import { Skeleton } from 'antd'
import { makeStyles } from '@material-ui/core'
import ResponsiveCard from '../../shared/ResponsiveCard'

const MemberGeneralInformation = () => {
  const classes = useStyles()
  const { loading, data: member } = useSelector((state) => state.member)

  return (
    <ResponsiveCard style={{ overflowX: 'hidden' }}>
      <Skeleton loading={loading} active>
        {member && (
          <Row style={{ textAlign: 'center' }}>
            <Col xs={12} sm='auto'>
              <p>Issues Counter</p>
              <h4>{member.issues?.leght || 0}</h4>
            </Col>
            <Col xs={12} sm='auto'>
              <p>Last DB Sync</p>
              <h5>
                {member?.mongoLastUpdate
                  ? new Date(member.mongoLastUpdate).toLocaleDateString()
                  : '-'}
              </h5>
            </Col>
            <Col xs={12} sm='auto'>
              <p className={classes.col}>Salesforce Syncable</p>
              <h5>
                {member.salesforceError ? (
                  <HighlightOffRoundedIcon
                    fontSize='large'
                    style={{ color: '#ff0000' }}
                  />
                ) : (
                  <CheckCircleOutlineRoundedIcon
                    fontSize='large'
                    style={{ color: '#008000' }}
                  />
                )}
              </h5>
            </Col>
            <Col xs={12} sm='auto'>
              <p
                style={{
                  fontSize: 'small'
                }}
              >
                Last Salesforce Sync
              </p>
              <h5>
                {new Date(member.salesforceLastUpdate).toLocaleDateString()}
              </h5>
            </Col>
          </Row>
        )}
      </Skeleton>
    </ResponsiveCard>
  )
}

const useStyles = makeStyles((theme) => ({
  p: {
    fontSize: '1.5rem'
  },
  [theme.breakpoints.down('sm')]: {
    p: {
      fontSize: '.8rem'
    }
  }
}))

export default MemberGeneralInformation
