import React from 'react'
import { useSelector } from 'react-redux'
import { Skeleton, Descriptions } from 'antd'
import { makeStyles } from '@material-ui/core'
import ResponsiveCard from '../../shared/ResponsiveCard'

const MemberInformation = () => {
  const classes = useStyles()
  const { loading, data: member } = useSelector((state) => state.member)
  const oldAdminMemberUrl = `${process.env.REACT_APP_API_ADMIN}/members/${member?.id}`
  const lifersCancelationUrl = `${process.env.REACT_APP_LIFESTYLE_CANCELATION_URL}/${member?.hashToken}`
  const chargifyMemberUrl = member?.chargifyCustomerId
    ? `https://${process.env.REACT_APP_CHARGIFY_SITE}.chargify.com/customers/${member?.chargifyCustomerId}`
    : ''

  return (
    <ResponsiveCard>
      {member && (
        <Skeleton loading={loading} active>
          <Descriptions
            className={classes.descriptionsTable}
            title='Member Information'
            bordered
            column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
          >
            <Descriptions.Item label='Email'>{member.email}</Descriptions.Item>
            <Descriptions.Item label='Hash Token'>
              {member.hashToken}
            </Descriptions.Item>
            <Descriptions.Item label='First Name'>
              {member.firstName}
            </Descriptions.Item>
            <Descriptions.Item label='Last Name'>
              {member.lastName}
            </Descriptions.Item>
            <Descriptions.Item label='Gender'>
              {member.gender}
            </Descriptions.Item>
            <Descriptions.Item label='Mobile'>
              {member.mobile}
            </Descriptions.Item>
            <Descriptions.Item label='Post Code'>
              {member.postCode}
            </Descriptions.Item>
            <Descriptions.Item label='Screen Name'>
              {member.screenName}
            </Descriptions.Item>
            <Descriptions.Item label='Roles'>
              <ul>
                {member?.roles?.map((r) => (
                  <li key={r}>{r}</li>
                ))}
              </ul>
            </Descriptions.Item>
            <Descriptions.Item label='Salesforce ID'>
              {member.salesforceId}
            </Descriptions.Item>
            <Descriptions.Item label='Link to old admin' span={2}>
              <a href={oldAdminMemberUrl} target='_blank' rel='noreferrer'>
                {oldAdminMemberUrl}
              </a>
            </Descriptions.Item>
            <Descriptions.Item label='Link to Lifestyle Cancellation' span={2}>
              <a href={lifersCancelationUrl} target='_blank' rel='noreferrer'>
                {lifersCancelationUrl}
              </a>
            </Descriptions.Item>
            <Descriptions.Item label='Link to Chargify' span={2}>
              <a href={chargifyMemberUrl} target='_blank' rel='noreferrer'>
                {chargifyMemberUrl}
              </a>
            </Descriptions.Item>
          </Descriptions>
        </Skeleton>
      )}
    </ResponsiveCard>
  )
}

const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.down('sm')]: {
    descriptionsTable: {
      '& .ant-descriptions-item-label': {
        padding: theme.paddings.sm
      }
    }
  }
}))

export default MemberInformation
