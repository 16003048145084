export const GET_MEMBERSHIP_BY_ID_REQUEST = 'GET_MEMBERSHIP_BY_ID_REQUEST'
export const GET_MEMBERSHIP_BY_ID_SUCCESS = 'GET_MEMBERSHIP_BY_ID_SUCCESS'
export const GET_MEMBERSHIP_BY_ID_FAIL = 'GET_MEMBERSHIP_BY_ID_FAIL'

export const UPDATE_MEMBERSHIP_PAYMENT_OPTIONS_REQUEST =
  'UPDATE_MEMBERSHIP_PAYMENT_OPTIONS_REQUEST'
export const UPDATE_MEMBERSHIP_PAYMENT_OPTIONS_SUCCESS =
  'UPDATE_MEMBERSHIP_PAYMENT_OPTIONS_SUCCESS'
export const UPDATE_MEMBERSHIP_PAYMENT_OPTIONS_FAIL =
  'UPDATE_MEMBERSHIP_PAYMENT_OPTIONS_FAIL'

export const ADD_SUBSCRIPTION_TO_MEMBERSHIP_REQUEST =
  'ADD_SUBSCRIPTION_TO_MEMBERSHIP_REQUEST'
export const ADD_SUBSCRIPTION_TO_MEMBERSHIP_SUCCESS =
  'ADD_SUBSCRIPTION_TO_MEMBERSHIP_SUCCESS'
export const ADD_SUBSCRIPTION_TO_MEMBERSHIP_FAIL =
  'ADD_SUBSCRIPTION_TO_MEMBERSHIP_FAIL'

export const UPDATE_MEMBERSHIP_PAYMENT_PLAN_REQUEST =
  'UPDATE_MEMBERSHIP_PAYMENT_PLAN_REQUEST'
export const UPDATE_MEMBERSHIP_PAYMENT_PLAN_SUCCESS =
  'UPDATE_MEMBERSHIP_PAYMENT_PLAN_SUCCESS'
export const UPDATE_MEMBERSHIP_PAYMENT_PLAN_FAIL =
  'UPDATE_MEMBERSHIP_PAYMENT_PLAN_FAIL'

export const SYNC_MEMBERSHIP_WITH_CHARGIFY_REQUEST =
  'SYNC_MEMBERSHIP_WITH_CHARGIFY_REQUEST'
export const SYNC_MEMBERSHIP_WITH_CHARGIFY_SUCCESS =
  'SYNC_MEMBERSHIP_WITH_CHARGIFY_SUCCESS'
export const SYNC_MEMBERSHIP_WITH_CHARGIFY_FAIL =
  'SYNC_MEMBERSHIP_WITH_CHARGIFY_FAIL'

export const REVOKE_CANCELLATION_REQUEST = 'REVOKE_CANCELLATION_REQUEST'
export const REVOKE_CANCELLATION_SUCCESS = 'REVOKE_CANCELLATION_SUCCESS'
export const REVOKE_CANCELLATION_FAIL = 'REVOKE_CANCELLATION_FAIL'

export const REACTIVATE_MEMBERSHIP_REQUEST = 'REACTIVATE_MEMBERSHIP_REQUEST'
export const REACTIVATE_MEMBERSHIP_SUCCESS = 'REACTIVATE_MEMBERSHIP_SUCCESS'
export const REACTIVATE_MEMBERSHIP_FAIL = 'REACTIVATE_MEMBERSHIP_FAIL'

export const CANCEL_MEMBERSHIP_REQUEST = 'CANCEL_MEMBERSHIP_REQUEST'
export const CANCEL_MEMBERSHIP_SUCCESS = 'CANCEL_MEMBERSHIP_SUCCESS'
export const CANCEL_MEMBERSHIP_FAIL = 'CANCEL_MEMBERSHIP_FAIL'

export const GET_MEMBERSHIPS_REQUEST = 'GET_MEMBERSHIPS_REQUEST'
export const GET_MEMBERSHIPS_SUCCESS = 'GET_MEMBERSHIPS_SUCCESS'
export const GET_MEMBERSHIPS_FAIL = 'GET_MEMBERSHIPS_FAIL'

export const GET_MEMBERSHIP_PENDING_REQUEST = 'GET_MEMBERSHIPS_PENDING_REQUEST'
export const GET_MEMBERSHIP_PENDING_SUCCESS = 'GET_MEMBERSHIP_PENDING_SUCCESS'
export const GET_MEMBERSHIP_PENDING_FAIL = 'GET_MEMBERSHIP_PENDING_FAIL'

export const DELETE_MEMBERSHIP_REQUEST = 'DELETE_MEMBERSHIP_REQUEST'
export const DELETE_MEMBERSHIP_SUCCESS = 'DELETE_MEMBERSHIP_SUCCESS'
export const DELETE_MEMBERSHIP_FAIL = 'DELETE_MEMBERSHIP_FAIL'
