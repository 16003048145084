import React from 'react'
import { useLocation } from 'react-router'
import { Result, Button } from 'antd'
import { Link } from 'react-router-dom'

function Error404Page() {
  const location = useLocation()
  return (
    <Result
      status='404'
      title='Ooops! Something is is wrong here.'
      subTitle={`This is a 404 error, which means you are trying to access a page that doesn't exist. Let your Dev Dream Team know that you tried to access ${location.pathname} and it happened!`}
      extra={
        <Button type='primary'>
          <Link to='/dashboard'>Back Home</Link>
        </Button>
      }
      s
    />
  )
}

export default Error404Page
