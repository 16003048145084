import axios from '../AxiosWrapper'

import {
  CANCELLATION_REPORT_REQUEST,
  CANCELLATION_REPORT_SUCCESS,
  CANCELLATION_REPORT_FAIL
} from '../constants/reports.constants'

export const cancellationReport = (onSuccess) => async (dispatch) => {
  dispatch({ type: CANCELLATION_REPORT_REQUEST })
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_HOST}admin/v1/reports/cancellation`
    )
    dispatch({
      type: CANCELLATION_REPORT_SUCCESS,
      payload: res.data
    })
    onSuccess()
  } catch (error) {
    dispatch({
      type: CANCELLATION_REPORT_FAIL
    })
  }
}

export const getCancellationReport = (onSuccess, endpoint) => async (
  dispatch
) => {
  dispatch({ type: CANCELLATION_REPORT_REQUEST })
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_HOST}admin/v1/reports${endpoint}`
    )
    dispatch({
      type: CANCELLATION_REPORT_SUCCESS,
      payload: res.data
    })
    onSuccess()
  } catch (error) {
    dispatch({
      type: CANCELLATION_REPORT_FAIL
    })
  }
}
