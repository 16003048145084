import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'

import LoginForm from './LoginForm'

import { login } from '../../redux/actions/User.actions'
import Alert from '../../shared/Alert'

const LoginFormWrapper = ({ login }) => {
  return (
    <>
      <Alert />
      <Formik
        initialValues={{
          email: '',
          password: ''
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email('Add a valid email')
            .required('Inform your email'),
          password: Yup.string().required('Inform your password')
        })}
        onSubmit={(values, { setSubmitting }) => {
          login(values)
          setSubmitting(false)
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <LoginForm submitForm={submitForm} isSubmitting={isSubmitting} />
        )}
      </Formik>
    </>
  )
}

LoginFormWrapper.propTypes = {
  login: PropTypes.func.isRequired
}

export default connect(null, { login })(LoginFormWrapper)
