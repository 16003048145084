import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'formik'
import { Col, Row } from 'react-bootstrap'
import { Button, CircularProgress } from '@material-ui/core/'

import PasswordField from '../../shared/form/PasswordField'
import TextField from '../../shared/form/BaseTextField'

const LoginForm = ({ isSubmitting, submitForm }) => {
  return (
    <Form noValidate className='w-100'>
      <Row>
        <Col>
          <TextField name='email' label='Email' type='email' autofocus={true} />
        </Col>
      </Row>
      <Row>
        <Col>
          <PasswordField name='password' label='Password' />
        </Col>
      </Row>
      <Row className='justify-content-end'>
        <Col xs={4}>
          <Button
            variant='contained'
            color='primary'
            onClick={submitForm}
            type='submit'
            className='w-100'
            disabled={isSubmitting}
          >
            {isSubmitting && <CircularProgress size={20} />}
            Login
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

LoginForm.propTypes = {
  submitForm: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired
}

LoginForm.defaultProps = {
  submitForm: undefined,
  isSubmitting: false
}

export default LoginForm
