import React from 'react'
import { useLocation } from 'react-router'
import { Breadcrumb } from 'antd'
import { Link } from 'react-router-dom'

const BreadcrumbTemplate = () => {
  const location = useLocation()
  const { pathname } = location
  let path = ''

  const getBreabcrumbs = () => {
    if (pathname === '/dashboard') return

    return pathname.split('/')
  }

  return (
    <Breadcrumb
      className='mb-1 mt-5 pl-3 pl-lg-4 pl-md-4 pl-sm-3'
      style={{ overflowX: 'hidden' }}
    >
      <Breadcrumb.Item key='dash'>
        <Link to='/dashboard'>Home</Link>
      </Breadcrumb.Item>
      {getBreabcrumbs()?.map((item, i, arr) => {
        if (item) {
          path += `/${item}`
          let label = item
          if (arr.length - 1 !== i && label.length > 7) {
            label = `${label.substr(0, 5)}...`
          }

          return (
            <Breadcrumb.Item key={`${path}-${i}`}>
              <Link to={path} title={item}>
                {label}
              </Link>
            </Breadcrumb.Item>
          )
        }
        return <Breadcrumb.Item key={`${path}-${i}`}></Breadcrumb.Item>
      })}
    </Breadcrumb>
  )
}

export default BreadcrumbTemplate
