import axios from '../AxiosWrapper'
import { setAlert } from './Alert.actions'

import {
  GET_MEMBERS_FAIL,
  GET_MEMBERS_REQUEST,
  GET_MEMBERS_SUCCESS,
  GET_MEMBER_BY_ID_FAIL,
  GET_MEMBER_BY_ID_REQUEST,
  GET_MEMBER_BY_ID_SUCCESS,
  UPDATE_MEMBER_ON_CHARGIFY_FAIL,
  UPDATE_MEMBER_ON_CHARGIFY_REQUEST,
  UPDATE_MEMBER_ON_CHARGIFY_SUCCESS,
  UPDATE_MEMBER_FAIL,
  UPDATE_MEMBER_REQUEST,
  UPDATE_MEMBER_SUCCESS,
  UPDATE_MEMBER_MEMBERSHIPS_FAIL,
  UPDATE_MEMBER_MEMBERSHIPS_REQUEST,
  UPDATE_MEMBER_MEMBERSHIPS_SUCCESS,
  TRANSFER_MEMBERSHIPS_REQUEST,
  TRANSFER_MEMBERSHIPS_SUCCESS,
  TRANSFER_MEMBERSHIPS_FAIL,
  REMOVE_DELETE_MEMBER_REQUEST,
  REMOVE_DELETE_MEMBER_SUCCESS,
  REMOVE_DELETE_MEMBER_FAIL
} from '../constants/members.constants'

const config = {
  headers: {
    'Content-Type': 'application/json'
  }
}

const urlFormater = (url, queryStr) => {
  const link = url.includes('?') ? `${url}&` : `${url}?`
  return `${link}${queryStr}`
}

export const getMembers = (props) => async (dispatch) => {
  const {
    pagination,
    filter,
    sorter = { order: 'descend', columnKey: 'createdAt' }
  } = props || {}

  let url = `${process.env.REACT_APP_API_HOST}admin/v1/members`

  if (pagination.pageSize && pagination.current)
    url = urlFormater(
      url,
      `limit=${pagination.pageSize}&page=${pagination.current}`
    )

  if (filter) {
    for (const k in filter) {
      if (filter[k]) {
        url = urlFormater(url, `${k}=${encodeURIComponent(filter[k])}`)
      }
    }
  }

  if (sorter?.order) {
    let s = sorter.order === 'descend' ? '-' : ''
    s += sorter.columnKey
    url = urlFormater(url, `sort=${s}`)
  }

  try {
    dispatch({ type: GET_MEMBERS_REQUEST })
    const res = await axios.get(encodeURI(url))

    dispatch({ type: GET_MEMBERS_SUCCESS, payload: res.data })
  } catch (error) {
    dispatch({ type: GET_MEMBERS_FAIL })
  }
}

export const getDeletionRequests = (props) => async (dispatch) => {
  const {
    pagination,
    filter,
    sorter = { order: 'descend', columnKey: 'createdAt' }
  } = props || {}

  let url = `${process.env.REACT_APP_API_HOST}admin/v1/members/requests`

  if (pagination.pageSize && pagination.current)
    url = urlFormater(
      url,
      `limit=${pagination.pageSize}&page=${pagination.current}`
    )

  if (filter) {
    for (const k in filter) {
      if (filter[k]) {
        url = urlFormater(url, `${k}=${encodeURIComponent(filter[k])}`)
      }
    }
  }

  if (sorter?.order) {
    let s = sorter.order === 'descend' ? '-' : ''
    s += sorter.columnKey
    url = urlFormater(url, `sort=${s}`)
  }

  try {
    dispatch({ type: GET_MEMBERS_REQUEST })
    const res = await axios.get(encodeURI(url))

    dispatch({ type: GET_MEMBERS_SUCCESS, payload: res.data })
  } catch (error) {
    dispatch({ type: GET_MEMBERS_FAIL })
  }
}

export const getMemberById = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_MEMBER_BY_ID_REQUEST })
    const res = await axios.get(
      `${process.env.REACT_APP_API_HOST}admin/v1/members/${id}`
    )

    dispatch({ type: GET_MEMBER_BY_ID_SUCCESS, payload: res.data })
  } catch (error) {
    dispatch({ type: GET_MEMBER_BY_ID_FAIL })
  }
}

export const updateMember = (
  _id,
  values,
  setSubmitting,
  setMemberFormVisible
) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_MEMBER_REQUEST })
    const body = JSON.stringify(values)

    const res = await axios.put(
      `${process.env.REACT_APP_API_HOST}admin/v1/members/${_id}`,
      body,
      config
    )

    dispatch({
      type: UPDATE_MEMBER_SUCCESS,
      payload: res.data
    })
    dispatch(setAlert('Successfully updated member', 'success'))
  } catch (error) {
    dispatch({
      type: UPDATE_MEMBER_FAIL
    })
    dispatch(setAlert('Failed to update member'))
  } finally {
    setSubmitting(false)
    setMemberFormVisible(false)
  }
}

export const updateMemberOnChargify = (_id) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_MEMBER_ON_CHARGIFY_REQUEST })

    const res = await axios.post(
      `${process.env.REACT_APP_API_HOST}admin/v1/members/${_id}/update-chargify`,
      {},
      config
    )

    dispatch({
      type: UPDATE_MEMBER_ON_CHARGIFY_SUCCESS,
      payload: res.data
    })

    dispatch(setAlert('Member updated on Chargify', 'success'))
  } catch (error) {
    dispatch(setAlert('Failed to update member on Chargify'))
    dispatch({
      type: UPDATE_MEMBER_ON_CHARGIFY_FAIL
    })
  }
}

export const removeMemberData = (_id, history) => async (dispatch) => {
  try {
    dispatch({ type: REMOVE_DELETE_MEMBER_REQUEST })

    const res = await axios.delete(
      `${process.env.REACT_APP_API_HOST}admin/v1/members/${_id}`
    )
    
    history.push('/members')
    dispatch({
      type: REMOVE_DELETE_MEMBER_SUCCESS
    })

    dispatch(setAlert(res.data, 'success'))
  } catch (error) {
    dispatch(setAlert('Failed to delete this member'))
    dispatch({
      type: REMOVE_DELETE_MEMBER_FAIL
    })
  }
}

export const removeMemberDeleteRequest = (_id, history) => async (dispatch) => {
  try {
    dispatch({ type: REMOVE_DELETE_MEMBER_REQUEST })

    await axios.delete(
      `${process.env.REACT_APP_API_HOST}admin/v1/members/requests/${_id}`
    )

    history.push('/members')
    dispatch({
      type: REMOVE_DELETE_MEMBER_SUCCESS
    })

    dispatch(setAlert('Member delete request removed successfully.', 'success'))
  } catch (error) {
    dispatch(setAlert('Failed to remove delete request.'))
    dispatch({
      type: REMOVE_DELETE_MEMBER_FAIL
    })
  }
}

export const transferMemberships = (
  _id,
  email,
  setSubmitting,
  setMemberFormVisible
) => async (dispatch) => {
  try {
    dispatch({ type: TRANSFER_MEMBERSHIPS_REQUEST })
    await axios.post(
      `${process.env.REACT_APP_API_HOST}admin/v1/members/${_id}/transfer-member`,
      { email },
      config
    )
    const res = await axios.put(
      `${process.env.REACT_APP_API_HOST}admin/v1/members/${_id}/memberships/lookup`,
      {},
      config
    )

    dispatch({
      type: TRANSFER_MEMBERSHIPS_SUCCESS,
      payload: res.data
    })

    dispatch(setAlert('Member transferred successfully', 'success'))
  } catch (error) {
    dispatch({
      type: TRANSFER_MEMBERSHIPS_FAIL
    })
    dispatch(setAlert('Failed to transfer memberships'))
  } finally {
    setSubmitting(false)
    setMemberFormVisible(false)
  }
}

export const syncMemberships = (_id) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_MEMBER_MEMBERSHIPS_REQUEST })

    const res = await axios.put(
      `${process.env.REACT_APP_API_HOST}admin/v1/members/${_id}/memberships/lookup`,
      {},
      config
    )

    dispatch({
      type: UPDATE_MEMBER_MEMBERSHIPS_SUCCESS,
      payload: res.data
    })

    dispatch(setAlert('Memberships list on Member updated', 'success'))
  } catch (error) {
    dispatch(setAlert('Failed to update Memberships list'))
    dispatch({
      type: UPDATE_MEMBER_MEMBERSHIPS_FAIL
    })
  }
}
