import React from 'react'
import { useSelector } from 'react-redux'
import { Skeleton, Descriptions, Tag } from 'antd'
import ResponsiveCard from '../../shared/ResponsiveCard'

const MembershipCancellation = () => {
  const { loading, data: membership, cancelled } = useSelector(
    (state) => state.membership
  )

  const cancellations = membership?.cancellations
  const cancellation = cancellations
    ? cancellations[cancellations.length - 1]
    : null
  return (
    <>
      {cancellation && !cancelled && (
        <ResponsiveCard>
          <Skeleton loading={loading} active>
            <Descriptions title='Membership Cancellation' bordered column={1}>
              <Descriptions.Item label='Reason'>
                {cancellation.reason}
              </Descriptions.Item>
              <Descriptions.Item label='Reason (Other)'>
                {cancellation.reasonId?.reason === 'Other' &&
                  cancellation.reason}
              </Descriptions.Item>
              <Descriptions.Item label='Migrated to (promotion)'>
                {cancellation.migratedTo}
              </Descriptions.Item>
              <Descriptions.Item label='Confirmation Date'>
                {cancellation.confirmedAt
                  ? new Date(cancellation.confirmedAt).toLocaleString()
                  : 'Not confirmed / finalized'}
              </Descriptions.Item>
              <Descriptions.Item label='Status'>
                {!cancellation.confirmedAt && (
                  <Tag color='cyan'>Process not completed</Tag>
                )}
                {cancellation.confirmedAt && cancellation.migratedTo && (
                  <Tag color='green'>Migrated to promo</Tag>
                )}
                {cancellation.confirmedAt &&
                  membership.scheduledCancelDate &&
                  membership.status === 'active' && (
                    <Tag color='orange'>Scheduled to cancel</Tag>
                  )}
                {cancellation.confirmedAt &&
                  membership.status === 'cancelled' && (
                    <Tag color='red'>Membership Cancelled</Tag>
                  )}
              </Descriptions.Item>
              <Descriptions.Item label='Date to Cancel'>
                {membership.scheduledCancelDate &&
                  new Date(membership.scheduledCancelDate).toLocaleString()}
              </Descriptions.Item>
            </Descriptions>
          </Skeleton>
        </ResponsiveCard>
      )}
    </>
  )
}

export default MembershipCancellation
