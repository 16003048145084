import React from 'react'
import { useSelector } from 'react-redux'
import { Skeleton, Descriptions } from 'antd'
import ResponsiveCard from '../../shared/ResponsiveCard'
import { makeStyles } from '@material-ui/core'

const MembershipInformation = () => {
  const classes = useStyles()
  const { loading, data: membership } = useSelector((state) => state.membership)

  return (
    <ResponsiveCard>
      {membership && (
        <Skeleton loading={loading} active>
          <Descriptions
            title='Membership Information'
            bordered
            column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
            className={classes.descriptions}
          >
            <Descriptions.Item label='Payment Method'>
              {membership.paymentMethod}
            </Descriptions.Item>
            <Descriptions.Item label='Billing Option'>
              {membership.billingOption}
            </Descriptions.Item>
            <Descriptions.Item label='Payment Plan'>
              {membership.paymentPlan?.name}
            </Descriptions.Item>
            <Descriptions.Item label='Total Paid'>
              {membership.totalPaidInCents
                ? `$ ${(parseInt(membership.totalPaidInCents) / 100).toFixed(
                    2
                  )}`
                : '$ --'}
            </Descriptions.Item>
          </Descriptions>
        </Skeleton>
      )}
    </ResponsiveCard>
  )
}

const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.down('sm')]: {
    descriptions: {
      '& .ant-descriptions-item-label': {
        padding: theme.paddings.xs
      }
    }
  }
}))

export default MembershipInformation
