import {
  MONTHLY_LIFERS_FAIL,
  MONTHLY_LIFERS_REQUEST,
  MONTHLY_LIFERS_SUCCESS,
  TOTAL_CANCELLATIONS_FAIL,
  TOTAL_CANCELLATIONS_REQUEST,
  TOTAL_CANCELLATIONS_SUCCESS,
  TOTAL_EBOOKS_FAIL,
  TOTAL_EBOOKS_REQUEST,
  TOTAL_EBOOKS_SUCCESS,
  TOTAL_LIFERS_BY_MONTH_FAIL,
  TOTAL_LIFERS_BY_MONTH_REQUEST,
  TOTAL_LIFERS_BY_MONTH_SUCCESS,
  TOTAL_LIFERS_BY_STATUS_FAIL,
  TOTAL_LIFERS_BY_STATUS_REQUEST,
  TOTAL_LIFERS_BY_STATUS_SUCCESS,
  TOTAL_LIFERS_FAIL,
  TOTAL_LIFERS_NUTRITION_FAIL,
  TOTAL_LIFERS_NUTRITION_REQUEST,
  TOTAL_LIFERS_NUTRITION_SUCCESS,
  TOTAL_LIFERS_REQUEST,
  TOTAL_LIFERS_SUCCESS,
  TOTAL_MONTHLY_LIFERS_FAIL,
  TOTAL_MONTHLY_LIFERS_REQUEST,
  TOTAL_MONTHLY_LIFERS_SUCCESS,
  TOTAL_ROUND_SIGNUPS_FAIL,
  TOTAL_ROUND_SIGNUPS_REQUEST,
  TOTAL_ROUND_SIGNUPS_SUCCESS,
  TOTAL_SIGNUPS_FAIL,
  TOTAL_SIGNUPS_REQUEST,
  TOTAL_SIGNUPS_SUCCESS,
  TOTAL_SUBSCRIPTIONS_BY_ROUND_FAIL,
  TOTAL_SUBSCRIPTIONS_BY_ROUND_REQUEST,
  TOTAL_SUBSCRIPTIONS_BY_ROUND_SUCCESS
} from '../constants/dashboard.constants'

export const totalLifersReducer = (state = {}, action) => {
  switch (action.type) {
    case TOTAL_LIFERS_REQUEST:
      return { loading: true }
    case TOTAL_LIFERS_SUCCESS:
      return {
        loading: false,
        totalLifers: action.payload
      }
    case TOTAL_LIFERS_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

export const totalLifersByStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case TOTAL_LIFERS_BY_STATUS_REQUEST:
      return { loading: true }
    case TOTAL_LIFERS_BY_STATUS_SUCCESS:
      return {
        loading: false,
        totalLifersByStatus: action.payload
      }
    case TOTAL_LIFERS_BY_STATUS_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

export const totalLifersByMonthReducer = (state = {}, action) => {
  switch (action.type) {
    case TOTAL_LIFERS_BY_MONTH_REQUEST:
      return { loading: true }
    case TOTAL_LIFERS_BY_MONTH_SUCCESS:
      return {
        loading: false,
        totalLifersByMonth: action.payload
      }
    case TOTAL_LIFERS_BY_MONTH_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

export const totalLifersNutritionReducer = (state = {}, action) => {
  switch (action.type) {
    case TOTAL_LIFERS_NUTRITION_REQUEST:
      return { loading: true }
    case TOTAL_LIFERS_NUTRITION_SUCCESS:
      return {
        loading: false,
        totalLifersNutrition: action.payload
      }
    case TOTAL_LIFERS_NUTRITION_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

export const totalCancellationsReducer = (state = {}, action) => {
  switch (action.type) {
    case TOTAL_CANCELLATIONS_REQUEST:
      return { loading: true }
    case TOTAL_CANCELLATIONS_SUCCESS:
      return {
        loading: false,
        totalCancellations: action.payload
      }
    case TOTAL_CANCELLATIONS_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

export const totalSubscriptionByRoundReducer = (state = {}, action) => {
  switch (action.type) {
    case TOTAL_SUBSCRIPTIONS_BY_ROUND_REQUEST:
      return { loading: true }
    case TOTAL_SUBSCRIPTIONS_BY_ROUND_SUCCESS:
      return {
        loading: false,
        totalSubscriptionsByRound: action.payload
      }
    case TOTAL_SUBSCRIPTIONS_BY_ROUND_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

export const totalEbooksReducer = (state = { ebooks: {} }, action) => {
  switch (action.type) {
    case TOTAL_EBOOKS_REQUEST:
      return { loading: true, ebooks: {} }
    case TOTAL_EBOOKS_SUCCESS:
      return {
        loading: false,
        ebooks: action.payload
      }
    case TOTAL_EBOOKS_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

export const totalMonthlyLifersReducer = (
  state = { totalMonthlyLifers: [] },
  action
) => {
  switch (action.type) {
    case TOTAL_MONTHLY_LIFERS_REQUEST:
    case MONTHLY_LIFERS_REQUEST:
      return { loading: true, totalMonthlyLifers: [] }
    case TOTAL_MONTHLY_LIFERS_SUCCESS:
    case MONTHLY_LIFERS_SUCCESS:
      return {
        loading: false,
        totalMonthlyLifers: action.payload
      }
    case TOTAL_MONTHLY_LIFERS_FAIL:
    case MONTHLY_LIFERS_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

export const totalRoundSignupsReducer = (
  state = { report: [], keys: [], rounds: [] },
  action
) => {
  switch (action.type) {
    case TOTAL_ROUND_SIGNUPS_REQUEST:
      return { loading: true, report: [], keys: [], rounds: [] }
    case TOTAL_ROUND_SIGNUPS_SUCCESS:
      return {
        loading: false,
        ...action.payload
      }
    case TOTAL_ROUND_SIGNUPS_FAIL:
      return {
        loading: false,
        report: [],
        keys: [],
        rounds: [],
        error: action.payload
      }
    default:
      return state
  }
}

export const totalSignupsReducer = (state = {}, action) => {
  switch (action.type) {
    case TOTAL_SIGNUPS_REQUEST:
      return { loading: true }
    case TOTAL_SIGNUPS_SUCCESS:
      return {
        loading: false,
        totalSignups: action.payload
      }
    case TOTAL_SIGNUPS_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}
