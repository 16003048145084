import React from 'react'

import { useSelector } from 'react-redux'
import { Skeleton, Tabs, Table } from 'antd'
import ComponentLoader from '../../shared/ComponentLoader'
import ResponsiveCard from '../../shared/ResponsiveCard'

import moment from 'moment'

const { TabPane } = Tabs

const MembershipDataTabs = () => {
  const { loading, data: membership } = useSelector((state) => state.membership)
  const pagination = {
    pageSize: 5
  }

  const subscriptionsColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a - b,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Round ID',
      dataIndex: 'roundId',
      key: 'roundId',
      sorter: (a, b) => a - b,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Round Name',
      dataIndex: 'roundName',
      key: 'roundName',
      sorter: (a, b) => a - b,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      sorter: (a, b) => new Date(a?.createdAt) - new Date(b?.createdAt),
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'descend',
      render: (c) => new Date(c).toLocaleString()
    }
    // {
    //   title: '',
    //   dataIndex: '_id',
    //   key: '_id',
    //   render: (_id) => {
    //     return (
    //       <>
    //         <Divider type='vertical' />
    //         {/* <Link style={{ textDecoration: 'underline' }}>view</Link> */}
    //         <a>View</a>
    //         <Divider type='vertical' />
    //         {/* <Link style={{ textDecoration: 'underline' }}>view</Link> */}
    //         <a>Change Round (add na drawer)</a>
    //         <Divider type='vertical' />
    //         {/* <Link style={{ textDecoration: 'underline' }}>view</Link> */}
    //         <a>Delete Subscription (add na drawer)</a>
    //         <Divider type='vertical' />
    //       </>
    //     )
    //   }
    // }
  ]

  const BillingHistoryColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a - b,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => a - b,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Success',
      dataIndex: 'success',
      key: 'success'
    },
    {
      title: 'Paid Revenue',
      dataIndex: 'paidRevenueInCents',
      key: 'paidRevenueInCents',
      render: (p) => `$ ${(parseInt(p) / 100).toFixed(2)}`
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (c) => moment(c, 'dd/MM/YYYY')
    },
    {
      title: 'Memo',
      dataIndex: 'memo',
      key: 'memo'
    }
  ]

  const IssuesColumns = [
    {
      title: 'Code',
      dataIndex: 'issueCode',
      key: 'issueCode',
      sorter: (a, b) => a - b,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Message',
      dataIndex: 'issueMessage',
      key: 'issueMessage',
      sorter: (a, b) => a - b,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => a - b,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (c) => new Date(c).toLocaleString()
    }
  ]

  return (
    <ResponsiveCard>
      {loading ? (
        <ComponentLoader />
      ) : (
        membership && (
          <Skeleton loading={loading} active>
            <Tabs type='card' style={{ overflowX: 'scroll' }}>
              <TabPane tab='Subscriptions' key='subscriptions'>
                <Table
                  rowKey={(record) => record._id}
                  columns={subscriptionsColumns}
                  dataSource={membership.subs}
                  pagination={pagination}
                />
              </TabPane>
              <TabPane tab='Billing History' key='billingHistory'>
                <Table
                  rowKey={(record) => record._id}
                  columns={BillingHistoryColumns}
                  dataSource={membership.billingHistory || []}
                  pagination={pagination}
                />
              </TabPane>
              <TabPane tab='Issues' key='issues'>
                <Table
                  rowKey={(record) => record.date}
                  columns={IssuesColumns}
                  dataSource={membership.issues}
                  pagination={pagination}
                />
              </TabPane>
            </Tabs>
          </Skeleton>
        )
      )}
    </ResponsiveCard>
  )
}

export default MembershipDataTabs
