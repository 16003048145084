import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import { SvgIcon } from '@material-ui/core'
import { Skeleton } from 'antd'
import MemberFormDrawer from './MemberFormDrawer'
import EmailFormDrawer from './EmailFormDrawer'
import MemberActions from './MemberActions'
import MembershipActions from '../memberships/MembershipActions'
import ResponsiveCard from '../../shared/ResponsiveCard'

const MemberActionCard = ({ members, memberships }) => {
  const { loading, data: member } = useSelector((state) => state.member)
  const [memberFormVisible, setMemberFormVisible] = useState(false)

  const [emailFormVisible, setEmailFormVisible] = useState(false)

  return (
    <ResponsiveCard style={{ overflowX: 'hidden' }}>
      <Skeleton loading={loading} avatar active>
        {member && (
          <>
            <MemberFormDrawer
              memberFormVisible={memberFormVisible}
              setMemberFormVisible={setMemberFormVisible}
              member={member}
            />
            <EmailFormDrawer
              emailFormVisible={emailFormVisible}
              setEmailFormVisible={setEmailFormVisible}
              member={member}
            />
            <Row style={{ padding: 0 }}>
              <Col xs={12} sm={4} md={12}>
                <SvgIcon
                  component={AccountCircleIcon}
                  style={{ width: '100px', height: '100px' }}
                  color='disabled'
                />
              </Col>
              <Col xs={12} sm={8} md={12}>
                <h3
                  style={{ marginBottom: '0' }}
                >{`${member.firstName} ${member.lastName}`}</h3>
                <p>{member.email}</p>
                <div style={{ color: 'rgba(0, 0, 0, 0.5)', fontSize: 'small' }}>
                  <p style={{ marginBottom: '0' }}>
                    {`Updated: ${new Date(member.updatedAt).toLocaleString()}`}
                  </p>
                  <p style={{ marginBottom: '0' }}>
                    {`Created: ${new Date(member.createdAt).toLocaleString()}`}
                  </p>
                </div>
              </Col>
            </Row>
            {members && <MemberActions />}
            {memberships && <MembershipActions />}
          </>
        )}
      </Skeleton>
    </ResponsiveCard>
  )
}

MemberActionCard.propTypes = {
  members: PropTypes.bool,
  memberships: PropTypes.bool
}

export default MemberActionCard
