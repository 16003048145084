import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Descriptions } from 'antd'
import { Alert, Col, Row } from 'react-bootstrap'
import { Button, CircularProgress, MenuItem } from '@material-ui/core/'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { updateMembershipPaymentPlan } from '../../redux/actions/Memberships.actions'
import SelectInput from '../../shared/form/SelectInput'
import ResponsiveDrawer from '../../shared/ResponsiveDrawer'

const validationSchema = Yup.object({
  membershipType: Yup.string().required('Select a Membership Type'),
  paymentPlan: Yup.string().required('Select a Payment Plan')
})

const ChangePaymentPlanFormDrawer = ({
  changePaymentPlanFormVisible,
  setChangePaymentPlanFormVisible,
  memberId,
  membership
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { data: membershipTypes } = useSelector(
    (state) => state.membershipTypes
  )

  const initialMembershipType = membershipTypes.find((mt) => {
    const pp = mt.paymentPlans.find((p) => {
      return p._id === membership.paymentPlan?._id
    })
    if (pp) return mt
    return false
  })

  const [selectedMembershipType, setSelectedMembershipType] = useState(
    initialMembershipType
  )

  const onClose = () => {
    setChangePaymentPlanFormVisible(false)
  }

  const initialValues = {
    membershipType: initialMembershipType?._id || '',
    paymentPlan: membership?.paymentPlan?._id || ''
  }

  const handleMembershipTypeChange = (e, formik) => {
    e.preventDefault()
    const membershipType = membershipTypes.find(
      (mt) => mt._id === e.target.value
    )
    setSelectedMembershipType(membershipType)
    formik.handleChange(e)
  }

  const handlePaymentPlanChange = (e, formik) => {
    e.preventDefault()
    formik.handleChange(e)
  }

  const handleFormSubmit = (values, { setSubmitting }) => {
    dispatch(
      updateMembershipPaymentPlan({
        memberId,
        membershipId: membership._id,
        values,
        setSubmitting,
        setChangePaymentPlanFormVisible
      })
    )
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleFormSubmit
  })

  return (
    <ResponsiveDrawer
      title='Change payment plan'
      onClose={onClose}
      visible={changePaymentPlanFormVisible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Alert variant='info'>
        Updating the payment plan wont reflect on Chargify and/or any other
        payment portal.
      </Alert>
      <Alert variant='info'>
        Please make sure you had updated the payment portal associated to the
        membership accordly.
      </Alert>
      <form onSubmit={formik.handleSubmit}>
        <Descriptions
          bordered
          className={classes.descriptionsWrapper}
          column={1}
        >
          <Descriptions.Item label='Membership Type'>
            <SelectInput
              classes={classes}
              id='membershipType'
              formik={formik}
              onChange={(e) => handleMembershipTypeChange(e, formik)}
            >
              {membershipTypes.map((mt) => (
                <MenuItem key={mt?._id} value={mt._id}>
                  {mt.name}
                </MenuItem>
              ))}
            </SelectInput>
          </Descriptions.Item>
          <Descriptions.Item label='Payment Plan'>
            <SelectInput
              classes={classes}
              id='paymentPlan'
              formik={formik}
              onChange={(e) => handlePaymentPlanChange(e, formik)}
            >
              {selectedMembershipType?.paymentPlans?.map((pp) => (
                <MenuItem key={pp._id} value={pp._id}>
                  {pp.name}
                </MenuItem>
              ))}
            </SelectInput>
          </Descriptions.Item>
        </Descriptions>
        <Row className='mt-3 justify-content-end'>
          <Col xs={12} md={3}>
            <Button
              className='w-100'
              variant='contained'
              color='primary'
              type='submit'
              disabled={formik.isSubmitting}
            >
              {formik.isSubmitting && <CircularProgress size={20} />}
              Save
            </Button>
          </Col>
          <Col xs={12} md={3}>
            <Button
              className='w-100'
              variant='outlined'
              type='button'
              disabled={formik.isSubmitting}
              onClick={onClose}
            >
              {formik.isSubmitting && <CircularProgress size={20} />}
              Cancel
            </Button>
          </Col>
        </Row>
      </form>
    </ResponsiveDrawer>
  )
}

const useStyles = makeStyles((theme) => ({
  descriptionsWrapper: {
    '& .ant-descriptions-item-label': {
      width: '33%'
    },

    '& .ant-descriptions-item-content': {
      padding: 0,

      '& input': {
        padding: '3px 7px 7px'
      }
    }
  },
  input: {
    '& .MuiInput-underline:before': {
      borderBottomColor: 'transparent'
    },
    '& .MuiInput-underline:hover': {
      borderBottomColor: 'rgba(0, 0, 0, 0.3)'
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: 'rgba(0, 0, 0, 0.3)'
    }
  }
}))

ChangePaymentPlanFormDrawer.propTypes = {
  changePaymentPlanFormVisible: PropTypes.bool,
  setChangePaymentPlanFormVisible: PropTypes.func,
  memberId: PropTypes.string.isRequired,
  membership: PropTypes.object.isRequired
}

export default ChangePaymentPlanFormDrawer
